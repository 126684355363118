
export default class Setting {
    constructor(qrAge) {
        // setting property values
        this.qr_age = qrAge;
    }

    static fromJson(json) {
        this.qr_age = json['qr_age'];
    }
}

