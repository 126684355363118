import initialState from './../initialState'
import types from './../actionTypes'

export default (state = initialState.historyStore, action = {}) => {

    switch(action.type) {

		case types.HISTORY_PENDING: {
			return {
				...state,
				is_pending: true,
				is_success: false
			}
		}

		case types.FETCH_HISTORY: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				histories: action.payload.docs
			}
        }
        
        case types.CREATE_HISTORY: {
			return {
				...state,
				is_pending: false,
				is_success: true,
			}
		}

        default: 
            return state
    }
}