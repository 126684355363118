import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import actions from './../redux/actions'

const calAge = (mins) => {
    switch (mins) {
      case 15 :
        return '15 mins';
      case 120 :
        return '2 hours';
      case 1440 :
        return '24 hours';
      case 21600 :
        return '15 days';
      default:
        return '15 mins';
    }
}

function Setting() {

    const dispatch = useDispatch();
    const setting = useSelector(state => state.settingStore.setting, {}) || {}
    
    useEffect(() => {
		dispatch(actions.FetchSetting())
    }, [dispatch])

    return (
        <div className="mui-row">
            <div className="mui-col-sm-12">

                <div className="mui-row" style={{marginTop: 20, backgroundColor: '#fff'}}>
                    <div className="mui-col-xs-12" style={{padding: 20}}>
                        
                        <Link to="/setting/qr">
                            <div className="d-flex" style={{paddingBottom: 20, borderBottom: '1px solid lightgrey'}}>
                                <div style={{ width: 30 }}>
                                    <i className="fa fa-qrcode"></i>
                                </div>
                                <div className="flex1">
                                    QR Life Time
                                </div>
                                <div className="mui--text-right" style={{ width: 60 }}>
                                    {setting.qr_age ? calAge(parseInt(setting.qr_age)) : '15 mins'}
                                </div>
                                <div className="mui--text-right" style={{ width: 30 }}>
                                    <i className="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </Link>

                        <Link to="/setting/appinfo">
                            <div className="d-flex" style={{paddingTop: 20}}>
                                <div style={{ width: 30 }}>
                                    <i className="fa fa-info-circle"></i>
                                </div>
                                <div className="flex1">
                                    App Info
                                </div>
                                <div className="mui--text-right" style={{ width: 30 }}>
                                    <i className="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Setting