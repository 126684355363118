import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'


function MainBackLayout({history, children, title, backPath}) {

    return (
        <div style={{height: '100%'}}>
            <header id="header" style={{left: 0, backgroundColor: '#F2F3F8'}}>
                <div className="mui-appbar mui--appbar-line-height">
                    <div className="mui-container-fluid d-flex" style={{justifyContent: 'space-between'}}>
                        <div className="flex0" style={{width: 30}}>
                            <button type="button" onClick={(e) => {e.preventDefault();history.replace(backPath)}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                            <button type="button" onClick={(e) => {e.preventDefault();history.replace(backPath)}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                        </div>
                        <div className="flex1 mui--text-center">
                            <span className="mui--text-title ">{title}</span>
                        </div>
                        <div className="flex0" style={{width: 30}}>
                            {/* <a href=""><i className="fa fa-save"></i></a> */}
                        </div>
                    </div>
                </div>
            </header>
            <div className="mui-container" style={{padding: '70px 15px 15px 15px'}}>
                {children}
            </div>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainBackLayout)