import config from './../config'

export const FetchSettingApi = () => {
    const auth_token = localStorage.getItem("card_token")
    var request = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'application/json'
        }
    };
    return fetch(config.BASE_API + '/cards/settings', request).then(res => res.json())
}

export const UpdateSettingApi = (params) => {
    const auth_token = localStorage.getItem("card_token")
    var request = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'application/json'
        }
    };
    return fetch(config.BASE_API + '/cards/settings', request).then(res => res.json())
}

export default { FetchSettingApi }