import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../redux/actions'
import InputText from './../components/form/InputText'
import { Formik } from 'formik'
import * as Yup from "yup"
import styled from 'styled-components'

function TemporaryQR({ actions, history }) {
    let [fileAvatar, setFileAvatar] = useState(null)
    let [fileIDcard, setFileIDcard] = useState(null)
    let [filePassport, setFilePassport] = useState(null)
    let [fileDrivingLicense, setFileDrivingLicense] = useState(null)

    const onSubmit = async (values) => {
        let qrdata = await actions.CreateQRDataGuest(values)
        if(fileAvatar || fileIDcard || filePassport || fileDrivingLicense) {
            await actions.UploadQRDataGuestAttachment({email: values.email, avatar: fileAvatar, idcard: fileIDcard, passport: filePassport, driving_license: fileDrivingLicense})
        }
        if(qrdata) {
            history.push({pathname: '/temp-qr/qr'})
        }
    }

    return (
        <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    name: Yup.mixed().required().label('Name'),
                    surname: Yup.mixed().required().label('Surname'),
                    email: Yup.string().required().label('Email'),
                    phone: Yup.mixed().required().label('Mobile number'),
                    checked: Yup.mixed().required()
                })
            }
        >
            {({handleSubmit, handleChange, values, setFieldValue}) => {
                return (
                    <form onSubmit={handleSubmit} className="mui-form">

                        <div className="mui-row">
                            <div className="mui-col-xs-12 mui--text-center">
                                <input type="file" id="fileAvatar" style={{display: 'none'}} onChange={(e) => setFileAvatar(e.target.files[0])}/>
                                <div onClick={() => document.getElementById('fileAvatar').click()}>
                                    { fileAvatar ? 
                                            <img src={URL.createObjectURL(fileAvatar)} style={{width: 120}} alt=""/> 
                                        : 
                                            <img src="/images/as.png" style={{width: 120}} alt=""/>
                                        
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mui-row" style={{marginTop: 20, backgroundColor: '#fff'}}>
                            <div className="mui-col-xs-12" style={{padding: 20}}>

                                    <p style={{fontSize: 16}}><strong>Personal Information</strong></p>
                                    
                                    <div className="mui-row">
                                        <div className="mui-col-xs-6">
                                            <InputText name="name" label="Name" onChange={handleChange} value={values.name}/>
                                        </div>
                                        <div className="mui-col-xs-6">
                                            <InputText name="surname" label="Surname" onChange={handleChange} value={values.surname}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-6">
                                            <InputText type="date" name="birth_date" label="Birth date" onChange={handleChange} value={values.birth_date || ''}/>
                                        </div>
                                        <div className="mui-col-xs-6">
                                            <InputText name="nationality" label="Nationality" onChange={handleChange} value={values.nationality}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12">
                                            <InputText type="email" name="email" label="Email" onChange={handleChange} value={values.email}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12">
                                            <InputText name="phone" label="Mobile number" onChange={handleChange} value={values.phone}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12 d-flex">
                                            <div className="flex1">
                                                <InputText name="citizen_id" label="iD number" onChange={handleChange} value={values.citizen_id || ''}/>
                                            </div>
                                            <input type="file" id="fileIdcard" style={{display: 'none'}} onChange={(e) => setFileIDcard(e.target.files[0])}/>
                                            {
                                                fileIDcard ? 
                                                    <ImageView onClick={() => document.getElementById('fileIdcard').click()} src={URL.createObjectURL(fileIDcard)} alt=""/>
                                                : 
                                                    <ImageThumb className="d-flex" onClick={() => document.getElementById('fileIdcard').click()}>
                                                        <i className="fa fa-picture-o"></i>
                                                    </ImageThumb>
                                            }
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12 d-flex">
                                            <div className="flex1">
                                                <InputText name="passport_id" label="Passport number" onChange={handleChange} value={values.passport_id || ''}/>
                                            </div>
                                            <input type="file" id="filePassport" style={{display: 'none'}} onChange={(e) => setFilePassport(e.target.files[0])}/>
                                            {
                                                filePassport ?
                                                    <ImageView onClick={() => document.getElementById('filePassport').click()} src={URL.createObjectURL(filePassport)} alt=""/>
                                                : 
                                                    <ImageThumb className="d-flex" onClick={() => document.getElementById('filePassport').click()}>
                                                        <i className="fa fa-picture-o"></i>
                                                    </ImageThumb>
                                            }
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12 d-flex">
                                            <div className="flex1">
                                                <InputText name="driving_license_id" label="Driving license number" onChange={handleChange} value={values.driving_license_id || ''}/>
                                            </div>
                                            <input type="file" id="fileDrivingLicense" style={{display: 'none'}} onChange={(e) => setFileDrivingLicense(e.target.files[0])}/>
                                            {
                                                fileDrivingLicense ?
                                                    <ImageView onClick={() => document.getElementById('fileDrivingLicense').click()} src={URL.createObjectURL(fileDrivingLicense)} alt=""/>
                                                :
                                                    <ImageThumb className="d-flex" onClick={() => document.getElementById('fileDrivingLicense').click()}>
                                                        <i className="fa fa-picture-o"></i>
                                                    </ImageThumb>
                                            }
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-md-12 d-flex">
                                            <div className="flex0" style={{whiteSpace: 'nowrap'}}>
                                                <div className="mui-checkbox">
                                                    <label>
                                                        <input type="checkbox" value="" checked={values.checked ? true : false} onClick={() => setFieldValue('checked', !values.checked)}/>
                                                        I accepted the
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex0" style={{marginTop: 10, whiteSpace: 'nowrap'}}>
                                                <a href="https://just-id.com/term-privacy.html" rel="noopener noreferrer" target="_blank"> Term and Privacy Policy</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mui-row mt-3">
                                        <div className="mui-col-md-12">
                                            <button className="mui-btn btn-full mui-btn--primary" type="submit" style={{borderRadius: 20}}>Submit</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

const ImageThumb = styled.div`
    margin-left: 10px; 
    width: 100px;
    height: 100px; 
    border: 1px solid grey;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
`;

const ImageView = styled.img`
    margin-left: 10px; 
    width: 100px;
    height: 100px; 
    margin-bottom: 10px;
    object-fit: cover;
`;

const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryQR)
