import types from './../actionTypes'
import config from './../../config'
import Qrdata from './../../models/Qrdata'

export const UploadQRDataGuestAttachment = (file) => {
    return async (dispatch) => {
        try {
            let formData = new FormData()
            Object.keys(file).forEach(f => {
                if(file[f]) {
                    formData.append(f, file[f])
                }
            })
            let res = await fetch(config.BASE_API + "/auth/qrid/guest/attachments" , {
                method: 'POST', 
                body: formData,
                headers: {
                    // "Authorization": "Bearer " + localStorage.getItem('card_token'),
                    // 'Content-Type': 'application/json'
                }
            }).then(res => res.json())
            dispatch({
                type: types.CREATE_QRDATA,
                payload: new Qrdata(res.data)
            })
            return res.data;
        } catch(err) {

        }


    }
}

export const CreateQRDataGuest = (params) => {
    return async (dispatch) => {
        dispatch({
			type: types.QRDATA_PENDING,
			payload: null
        })

        try {
            var request = {
                method: 'POST',
                body: JSON.stringify({ id_type: 'Just-iD Card', data: params }),
                headers: {
                    // 'Authorization': `Bearer ${auth_token}`,
                    'Content-Type': 'application/json'
                }
            };
            let res = await fetch(config.BASE_API + '/auth/qrid/guest', request).then(res => res.json())

            dispatch({
                type: types.CREATE_QRDATA,
                payload: new Qrdata(res.data)
            })

            return res.data

        } catch(e) {
            // error reading value
        }
    }
}

export const CreateQRData = (params) => {
	return async (dispatch) => {
		dispatch({
			type: types.QRDATA_PENDING,
			payload: null
        })

        try {
            const auth_token = localStorage.getItem("card_token")
            var request = {
                method: 'POST',
                body: JSON.stringify({ id_type: 'Just-iD Card', data: params }),
                headers: {
                    'Authorization': `Bearer ${auth_token}`,
                    'Content-Type': 'application/json'
                }
            };
            return fetch(config.BASE_API + '/auth/qrid', request)
            .then(res => res.json())
            .then(res => {
                dispatch({
                    type: types.CREATE_QRDATA,
                    payload: res.data
                })
                return res
            })
            .catch(err => {
            
            })
        } catch(e) {
            // error reading value
        }
    }
}