import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'
import MenuDrawer from './layout/MenuDrawer'

function onAuthStateChange(history, actions) {
    return actions.GetCurrentUser((res) => {
        if(res.error) {
            history.replace('/welcome')
        }
    });
}

function MainLayout({ children, actions, history, user, title, rightAction, bgColor }) {

    useEffect(() => {
        // if(!user._id) {
            // history.push('/login')
        // }
    }, [user])

    useEffect(() => {
        onAuthStateChange( history, actions );
    }, [history, actions]);

    const SignOut = () => {
        localStorage.removeItem('card_token')
        window.location = '/welcome'
    }

    const clickMenu = (e) => {
        e.preventDefault()
        window.showSidedrawer()
    }

    const linkMenu = (path) => {
        window.hideSidedrawer()
        history.push(path)
    }

    return (
        <div style={{height: '100%'}}>
            <MenuDrawer user={user} signOut={SignOut} linkMenu={linkMenu}/>
            <header id="header" style={{ backgroundColor: bgColor ? bgColor : 'transparent' }}>
                <div className="mui-appbar mui--appbar-line-height">
                    <div className="mui-container-fluid d-flex" style={{paddingLeft: 25}}>
                        <div style={{width: 30}}>
                            <button type="button" onClick={e => clickMenu(e)} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-show-sidedrawer">☰</button>
                            <button type="button" onClick={e => clickMenu(e)} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer">☰</button>
                        </div>
                        <div className="flex1 mui--text-center">
                            <span className="mui--text-title">
                                <div style={{ marginTop: 12}}>
                                    {title}
                                </div>
                            </span>
                        </div>
                        <div style={{width: 30}}>
                            {rightAction}
                        </div>
                    </div>
                </div>
            </header>
            <div id="content-wrapper">
                {children}
            </div>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)