import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Stepper from './../common/stepper'
import Button from './../common/button'
import InputText from './../form/InputText'

const Information = ({ onSubmit, initialValues }) => {
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    name: Yup.mixed().required().label('Name'),
                    surname: Yup.mixed().required().label('Surname'),
                    birth_date: Yup.string().required().label('Birth date'),
                    gender: Yup.string().required().label('Gender'),
                    email: Yup.string().label('Email'),
                    phone: Yup.mixed().required().label('Mobile number'),
                    checked: Yup.mixed().required()
                })
            }
        >
            {({handleSubmit, handleChange, values, setFieldValue, errors, isValid}) => {
                return (
                    <form onSubmit={handleSubmit} className="mui-form">
                        <div className="mui-row">
                            <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                            <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">

                                <div className="mui-row">
                                    <div className="mui-col-xs-12">
                                        <Stepper activeStep={1}/>
                                    </div>
                                </div>

                                <div className="mui-row mt-3">
                                    <div className="mui-col-xs-12">
                                        <div className="bg-white p-3">
                                            <p style={{fontSize: 16}}><strong>Please enter your information.</strong></p>
                                            
                                            <div className="mui-row">
                                                <div className="mui-col-xs-12">
                                                    <InputText name="name" label="Name" onChange={handleChange} value={values.name} required/>
                                                </div>
                                            </div>
                                        
                                            <div className="mui-row">
                                                <div className="mui-col-xs-12">
                                                    <InputText name="surname" label="Surname" onChange={handleChange} value={values.surname} required/>
                                                </div>
                                            </div>

                                            <div className="mui-row" style={{marginBottom: 15}}>
                                                <div className="mui-col-xs-12">
                                                    <label>Gender</label>
                                                    <div className="mui-radio">
                                                        <label>
                                                            <input type="radio" name="gender" value="male" checked={values.gender === 'male' ? true : false} onChange={handleChange}/>
                                                            Male
                                                        </label>
                                                        <label style={{marginLeft: 20}}>
                                                            <input type="radio" name="gender" value="female" checked={values.gender === 'female' ? true : false} onChange={handleChange}/>
                                                            Female
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mui-row">
                                                <div className="mui-col-xs-12">
                                                    <InputText noFloat type="date" name="birth_date" label="Birth date" onChange={handleChange} value={values.birth_date || ''} required/>
                                                </div>
                                            </div>

                                            <div className="mui-row">                                                    
                                                <div className="mui-col-xs-12">
                                                    <InputText name="nationality" label="Nationality" onChange={handleChange} value={values.nationality} required/>
                                                </div>
                                            </div>

                                            <div className="mui-row">
                                                <div className="mui-col-xs-12">
                                                    <InputText type="email" name="email" label="Email" onChange={handleChange} value={values.email}/>
                                                </div>
                                            </div>

                                            <div className="mui-row">
                                                <div className="mui-col-xs-12">
                                                    <InputText name="phone" label="Mobile number" onChange={handleChange} value={values.phone} required/>
                                                </div>
                                            </div>

                                            <div className="mui-row">                                                    
                                                <div className="mui-col-xs-12">
                                                    <InputText name="career" label="Career (optional)" onChange={handleChange} value={values.career}/>
                                                </div>
                                            </div>


                                            <div className="mui-row">
                                                <div className="mui-col-md-12 d-flex">
                                                    <div className="flex0" style={{whiteSpace: 'nowrap'}}>
                                                        <div className="mui-checkbox">
                                                            <label>
                                                                <input type="checkbox" value="" checked={values.checked ? true : false} onClick={() => setFieldValue('checked', !values.checked)}/>
                                                                I accepted the
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex0" style={{marginTop: 10, whiteSpace: 'nowrap'}}>
                                                        <a href="https://just-id.com/term-privacy.html" rel="noopener noreferrer" target="_blank" style={{color: 'blue'}}> Term and Privacy Policy</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mui-row mt-3">
                                                <div className="mui-col-md-12">
                                                    <Button bgColor="info" type="submit" label="Next" disabled={isValid ? false : true}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </form>
                )
            }}
        </Formik>
    )
}

export default Information