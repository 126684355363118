import initialState from './../initialState'
import types from './../actionTypes'

export default (state = initialState.settingStore, action = {}) => {

    switch(action.type) {

		case types.SETTING_PENDING: {
			return {
				...state,
				is_pending: true,
				is_success: false
			}
		}

		case types.FETCH_SETTING: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				setting: action.payload
			}
        }
        
        case types.UPDATE_SETTING: {
            return {
				...state,
				is_pending: false,
				is_success: true
			}
        }

        default: 
            return state
    }
}