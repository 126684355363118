import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../../redux/actions'
import Header from './../../components/layout/Header'
import AttachmentForm from './../../components/profile/attachment'
import theme from './../../theme'

function ProfileAttachment({ actions, avatar, history, information, user }) {

    if(!avatar) {
        history.replace('/profile/selfie')
    }

    const onSubmit = (values, files) => {
        let info = { ...information, ...values }
        actions.SetInformationAndAttachment(info, files.idcard, files.passport, files.driving_license)
        history.push({pathname: '/profile/confirm' })
    }

    return (
        <div style={{height: '100%'}}>
            <Header
                left={
                    <> 
                        <button type="button" onClick={(e) => {e.preventDefault();history.replace('/profile/information')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                        <button type="button" onClick={(e) => {e.preventDefault();history.replace('/profile/information')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                    </>
                }
                title={<span className="mui--text-title" style={{color: theme.color.white}}>CREATE PROFILE</span>}
                bgColor={theme.color.info}
            />
            <div className="mui-container" style={{padding: '70px 15px 15px 15px'}}>
                <AttachmentForm onSubmit={onSubmit}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
	return {
        user: state.profileStore.profile,
        avatar: state.temporaryStore.avatar,
        information: state.temporaryStore.information
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAttachment)
