import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import config from './../../config'
import actions from './../../redux/actions'
import styled from 'styled-components'

const DivLogo = styled.div`
    text-align: center;
    margin-bottom: 15px;
    > img {
        width: 118px;
    }
`;

const Avatar = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 27px;
    border: 2px solid #fff;
    margin-bottom: 5px;
`;

function MenuDrawer({ user, signOut, linkMenu }) {
    return (
        <div id="sidedrawer" className="mui--no-user-select">
            <div id="sidedrawer-brand">
                <DivLogo>
                    <img src="/images/card_logo_horizontal_white_min.png" alt=""/>
                </DivLogo>
                <div style={{textAlign: 'center'}}>
                    { user && user.attachment && user.attachment.avatar && user.attachment.avatar.disk_name ? 
                        <Avatar src={`${config.BASE_API}/${user.attachment.avatar.disk_name}?accessToken=${localStorage.getItem('card_token')}`} alt=""/> 
                    : <Avatar src="/images/as.png" alt=""/> }

                    <span style={{display: 'block', color: '#fff'}}>{user && user.information && user.information.name ? user.information.name : '' } {user && user.information && user.information.surname ? user.information.surname : '' }</span>
                    <span style={{display: 'block', color: '#fff'}}>{user && user.information && user.information.email ? user.information.email : '' } </span>
                </div>
            </div>
            <div className="mui-divider"></div>
            <ul>
                <li onClick={() => linkMenu('/')}><strong><i className="fa fa-credit-card"></i> <span style={{paddingLeft: 10}}>My Card</span></strong></li>
                <li onClick={() => linkMenu('/history')}><strong><i className="fa fa-history"></i> <span style={{paddingLeft: 10}}>History</span></strong></li>
                <li onClick={() => linkMenu('/setting')}><strong><i className="fa fa-cog"></i> <span style={{paddingLeft: 10}}>Setting</span></strong></li>
                <li onClick={() => signOut()}><strong><i className="fa fa-sign-out"></i> <span style={{paddingLeft: 10}}>Sign out</span></strong></li>
                {/* <li>
                    <strong>Category 3</strong>
                    <ul>
                    <li><a href="#">Item 1</a></li>
                    <li><a href="#">Item 2</a></li>
                    <li><a href="#">Item 3</a></li>
                    </ul>
                </li> */}
            </ul>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuDrawer)
