import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as io from 'socket.io-client'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import actions from './../redux/actions'
import Button from './../components/common/button'
import MyCard from './../components/mycard'

function getModalStyle() {
    const top = 40 //+ rand();
    const left = 46 //+ rand();
  
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '50%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function RequestQR({history, qrdata, actions}) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [modal, setModal] = useState(false)
    const [entrance, setEntrance] = useState({})

    const handleOpen = () => {
        setModal(true);
    };
    
    const handleClose = () => {
        setModal(false);
    };

    const confirmHistory = () => {
        actions.CreateHistory(entrance).then(res => {
            handleClose()
            history.replace('/')
        })
    }

    useEffect(() => {
        const connectionOptions =  {
            "force new connection" : true,
            "reconnection": true,
            "reconnectionDelay": 2000,                  //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
            "reconnectionDelayMax" : 60000,             //1 minute maximum delay between connections
            "reconnectionAttempts": "Infinity",         //to prevent dead clients, having the user to having to manually reconnect after a server restart.
            "timeout" : 10000,                           //before connect_error and connect_timeout are emitted.
            "transports" : ["websocket"]                //forces the transport to be only websocket. Server needs to be setup as well/
        };
        const socket = io('https://app.just-id.com', connectionOptions);
        socket.on('connect', () => {
            console.log("connected")
        })
        socket.on('cards-qrid-histories-' + qrdata.qrid , (data) => {
            setEntrance(data)
            handleOpen()
        })
        return () => {

        }
    }, [qrdata])
    useEffect(() => {
        if(!qrdata || !qrdata.qrid) {
            history.replace('/')
        }
    }, [qrdata, history])

    return (
        <div className="mui-container">
            <MyCard qrdata={qrdata}/>
            <div className="mui-row">
                <div className="mui-col-xs-12 mui--text-center">
                    <Button full={false} className="mui-btn" bgColor="purple" onClick={() => history.replace('/')} type="button" label="Close"/>
                </div>
            </div>
            <Modal
                open={modal}
                disableBackdropClick={true}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableRestoreFocus={true}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <h3 id="simple-modal-title" style={{textAlign: 'center'}}>
                        {entrance.event_name === 'checkin' ? 'Check-in ' : ''} 
                        {entrance.event_name === 'checkout' ? 'Check-out ' : ''} 
                        at {entrance && entrance.location ? entrance.location.name + ', ' + entrance.location.location_name : ''}
                    </h3>
                    <h4 style={{textAlign: 'center'}}>
                        Save the 
                        {entrance.event_name === 'checkin' ? ' check-in ' : ''} 
                        {entrance.event_name === 'checkout' ? ' check-out ' : ''} 
                        history?
                    </h4>
                    <div style={{marginTop: 40}}>
                        <Button className="mui-btn" bgColor="info" onClick={() => confirmHistory()} type="button" label="Save"/>
                    </div>
                    <div>
                        <Button className="mui-btn" bgColor="grey" onClick={() => setModal(false)} type="button" label="Close"/>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
	return {
        qrdata: state.qrdataStore.qrdata
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(RequestQR)
