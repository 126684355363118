import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from "yup"
import actions from '../redux/actions'
import InputText from './../components/form/InputText'

function ForgotPassword({ actions, history }) {

    const onSubmit = async (values) => {
        let res = await actions.AuthForgotPassword(values)
        if(res) {
            history.push({pathname: '/forgotpassword/verify', state: {email: values.email} })
        }
    }

    return (
        <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    email: Yup.string().required().label('Email')
                })
            }
        >
            {({handleSubmit, handleChange, values}) => {
                return (
                    <form className="mui-form" onSubmit={handleSubmit}>
                        <InputText type="email" name="email" label="Email" value={values.email} onChange={handleChange}/>

                        <div className="mui-row">
                            <div className="mui-col-md-12">
                                <button className="mui-btn btn-full mui-btn--primary" type="submit" style={{borderRadius: 20}}>Submit</button>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}


const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
