import types from './../actionTypes'

export const SetAvatar = (avatar) => {
    return async (dispatch) => {
        dispatch({
            type: types.SET_AVATAR,
            payload: avatar
        })
    }
}

export const SetInformation = (information) => {
    return async (dispatch) => {
        dispatch({
            type: types.SET_INFORMATION,
            payload: information
        })
    }
}

export const SetAttachment = (idcard, passport, driving_license) => {
    return async (dispatch) => {
        dispatch({
            type: types.SET_ATTACHMENT,
            payload: {
                idcard,
                passport,
                driving_license
            }
        })
    }
}

export const SetInformationAndAttachment = (information, idcard, passport, driving_license) => {
    return async (dispatch) => {
        dispatch({
            type: types.SET_INFORMATION_ATTACHMENT,
            payload: {
                information,
                idcard,
                passport,
                driving_license
            }
        })
    }
}
