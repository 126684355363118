import initialState from './../initialState'
import types from './../actionTypes'

export default (state = initialState.qrdataStore, action = {}) => {

    switch(action.type) {

		case types.QRDATA_PENDING: {
			return {
				...state,
				is_pending: true,
				is_success: false
			}
		}

		case types.CREATE_QRDATA: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				qrdata: action.payload
			}
		}

        default: 
            return state
    }
}