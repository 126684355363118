import types from './../actionTypes'
import Setting from './../../models/Setting'
import { FetchSettingApi, UpdateSettingApi } from './../../api/setting'

const settingPending = () => ({
    type: types.SETTING_PENDING,
    payload: null
})

export const FetchSetting = (callback) => {
	return async (dispatch) => {
		dispatch(settingPending())

        try {
            let res = await FetchSettingApi()
            if(res.error) {
                callback(res, null)
            } else {
                dispatch({
                    type: types.FETCH_SETTING,
                    payload: {
                        setting: Setting.fromJson(res.data)
                    }
                })
            }
        } catch(e) {
            // error reading value
        }
    }
}

export const UpdateSetting = (params) => {
	return async (dispatch) => {
		dispatch(settingPending())

        try {
            let res = await UpdateSettingApi(params)
            dispatch({
                type: types.UPDATE_SETTING,
                payload: res
            })
            return res
        } catch(e) {
            // error reading value
        }
    }
}