import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import config from './../config'
import actions from './../redux/actions'
import Button from './../components/common/button'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const TextTitle = styled.span`
    font-size: 24px;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
`;


const useStyles = makeStyles(theme => ({
    icon: {
        color: 'green'
    },
}));

// function onAuthStateChange(history, actions) {
//     return actions.GetCurrentUser((res) => {
//         if(res.error) {
//             history.replace('/welcome')
//         }
//     });
// }

function Main({ profile, history, actions, is_pending }) {
    
    const classes = useStyles()
    const [destination, setDestination] = useState('')
    const [notes, setNotes] = useState('')
    const types = { level0: 'Zero', level1: 'Standard' }

    // useEffect(() => {
    //     onAuthStateChange( history, actions );
    // }, [history, actions]);

    const CreateQRData = () => {
        window.openLoading()
        let attachment = {}
        let keys = ['avatar', 'idcard', 'passport', 'driving_license'];
        for(let k of keys) {
            if(profile.attachment[k] && profile.attachment[k].disk_name) {
                attachment[k] = profile.attachment[k].disk_name
            }
        }

        actions.CreateQRData({...profile.information, jcid: profile.jcid, attachment: profile.attachment, phone_verify: profile.phone_verify, email_verify: profile.email_verify, kyc_level: profile.kyc_level, notes, place_to: destination }).then(() => {
            window.closeLoading()
            history.push('/requestqr')
        })
    }

    return (
        <div className="mui-row">
            <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                {
                    is_pending ? null :
                        <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">
                            { !profile.phone_verify || profile?.phone_verify === false ? <Alert severity="warning">You have not verify the phone! <Link to={{pathname: '/verify/phone', state: { prevPath: '/'} }} style={{ color: '#2196F3'}}>click here</Link> to verify</Alert> : null }
                            { /* !profile.email_verify || profile?.email_verify === false ? <Alert className="mt-3" severity="warning">You have not verify the email! <Link to={{pathname: '/verify/email', state: { prevPath: '/'} }} style={{ color: '#2196F3'}}>click here</Link> to verify</Alert> : null */ }
                            <div className="mui-panel mt-3" style={{paddingBottom: 25, background: 'white url("/images/card-bg.jpg") repeat'}}>

                                <div className="mui-row mt-4">
                                    <div className="mui-col-xs-5 mui--text-right" style={{ paddingTop: 8 }}>
                                        { profile?.attachment?.avatar && profile?.attachment?.avatar?.disk_name ? <img src={`${config.BASE_API}/${profile?.attachment?.avatar?.disk_name}?accessToken=${localStorage.getItem('card_token')}`} style={{borderRadius: 5, height: 120, objectFit: 'cover', width: 100, border: '2px solid grey'}} alt=""/> : <img src="/images/as.png" alt="" style={{width: 100, border: '2px solid grey'}}/> }
                                    </div>
                                    <div className="mui-col-xs-7">
                                        <TextTitle>{profile?.information?.name}</TextTitle>
                                        <TextTitle>{profile?.information?.surname}</TextTitle>
                                        { profile?.jcid && <p className="mt-1" style={{ marginBottom: 5 }}>{profile?.jcid}</p> }
                                        <p className="mt-1" style={{ marginBottom: 5 }}>KYC level: <strong>{profile && profile.kyc_level ? types['level'+profile?.kyc_level] : ''}</strong></p>
                                        <p className="mt-1" style={{ marginBottom: 5 }}>Email: {profile?.information?.email} {profile.email_verify ? <VerifiedUserIcon fontSize="small" className={classes.icon}/> : null}</p>
                                        <p className="mt-1" style={{ marginBottom: 5 }}>Phone: {profile?.information?.dial_code + ' ' + profile?.information?.phone } {profile.phone_verify ? <VerifiedUserIcon fontSize="small" className={classes.icon}/> : null}</p>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="mui-textfield mui-textfield--float-label">
                                <input type="text" name="destination" onChange={(e) => setDestination(e.target.value)} value={destination}/>
                                <label>Destination</label>
                            </div>

                            <div className="mui-textfield mui-textfield--float-label">
                                <input type="text" name="notes" onChange={(e) => setNotes(e.target.value)} value={notes}/>
                                <label>Note</label>
                            </div>

                            <div className="mui-row">
                                <div className="mui-col-md-12">
                                    {/* <Link to={{pathname: 'requestqr', state: { qrdata: null } }}  className="mui-btn mui-btn--primary" style={{display: 'block', borderRadius: 20, backgroundColor: '#D7E0F9', color: '#2633C5'}}>Request QR</Link> */}
                                    <Button className="mui-btn" bgColor="info" type="button" onClick={() => CreateQRData()} label="Open My Card"/>
                                </div>
                            </div>

                        </div>
                }
        </div>
        
    )
}


const mapStateToProps = (state, ownProps) => {

	return {
        is_pending: state.authStore.is_pending,
        profile: state.authStore.user,
        qrdata: state.qrdataStore.qrdata,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
