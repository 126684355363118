import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from './../redux/actions'

function LineCallback({location, history}) {

    let code = new URLSearchParams(location.search).get("code")
    if(!code) {
        history.replace('/auth/login')
    }

    const dispatch = useDispatch();
    const is_success = useSelector(state => state.authStore.is_success, false) || false
    
    useEffect(() => {
        dispatch(actions.AuthGetTokenFromLine({ code }))
    }, [dispatch, code])


    useEffect(() => {
        if(is_success) {
            // console.log(is_success)
            // history.push('/login')
            // window.location = '/#/home'
            history.replace('/home')
        }
    }, [is_success, history])
    return (
        <div className="mui-container">
            

        </div>
    )
}

export default LineCallback