import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from './common/button'

const OTPVerifyForm = ({ onSubmit, via, to, onResend }) => {
    return (
        <Formik
            initialValues={{}}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    code: Yup.string().length(6).required().label('Verification code'),
                })
            }
        >
            {({handleSubmit, handleChange, values}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="mui--text-center">
                            For security and verify {via}, a verification code has been sent to your {via}
                        </div>
                        <div className="mui--text-center mt-2">
                            <strong>{to}</strong>
                        </div>
            
                        <div className="mui-textfield mui-textfield--float-label mui--text-center mt-2">
                            <input type="text" className="mui--text-center" name="code" value={values.code} onChange={handleChange}/>
                            <label>Enter verification code</label>
                        </div>
            
                        <div className="mui--text-center">
                            <Button bgColor="info" type="submit" label="Confirm"/>
                        </div>
            
                        <div className="mui--text-right mt-3">
                            Haven't received your code yet? <button type="button" onClick={() => onResend()} style={{ color: '#2196F3'}}>Click here</button> to resend.
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

export default OTPVerifyForm