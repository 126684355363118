import Profile from './../models/Profile'
import Qrdata from './../models/Qrdata'
import Setting from './../models/Setting'

export default {
    authStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        message: '',
        user: new Profile()
    },
    settingStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        setting: new Setting(15)
    },
    profileStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        user: new Profile()
    },
    qrdataStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        qrdata: new Qrdata()
    },
    temporaryStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        avatar: null,
        idcard: null,
        passport: null,
        driving_license: null,
        information: {}
    },
    historyStore: {
        is_pending: false,
        is_success: false,
        is_fail: false,
        histories: []
    }
}