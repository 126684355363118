import config from './../config'

export const FetchProfile = () => {
    const auth_token = localStorage.getItem("card_token")
    var request = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'application/json'
        }
    };
    return fetch(config.BASE_API + '/auth/me', request).then(res => res.json())
}

export default { FetchProfile }