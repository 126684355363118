import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'
import MyCard from './../components/mycard'

function TemporaryQRData({history, qrdata}) {

    useEffect(() => {
        if(!qrdata || !qrdata.qrid) {
            history.replace('/welcome')
        }
    }, [qrdata, history])

    return (
        <div className="mui-container">
            <MyCard qrdata={qrdata}/>
            <div className="mui-row">
                <div className="mui-col-xs-12 mui--text-center">
                    <button className="mui-btn mui-btn--primary" type="button" onClick={() => window.print()} style={{marginTop: 30, borderRadius: 20, backgroundColor: '#D7E0F9', color: '#2633C5'}}>Print</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
	return {
        qrdata: state.qrdataStore.qrdata
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryQRData)
