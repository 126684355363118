import { Information } from './Profile'

export default class Qrdata {
    constructor(props = {}) {
        this.data = new Information(props.data);
        this.qrid = props.qrid || null;
        this.id_type = props.id_type || null;
        this.expires = props.expires || null;
        this.started = props.started || null;
        this.user = props.user || {};
    }
}
