import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import actions from './../redux/actions'

function History() {
    
    const dispatch = useDispatch();
    const histories = useSelector(state => state.historyStore.histories, []) || []
    
    useEffect(() => {
        dispatch(actions.FetchHistories())
    }, [dispatch])

    return (
        <div className="mui-row">
            <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
            <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">
                <div style={{marginTop: 20, backgroundColor: '#fff'}}>    
                {
                    histories && histories.map((h) => (
                        <div key={h.qrid} className="d-flex" style={{padding: 10, borderBottom: '1px solid lightgrey'}}>
                            <div className="flex1">
                                { h.created_at ? dayjs(h.created_at).format('DD/MM/YYYY ') : '' }
                                {h.event_name === 'checkin' ? ' check-in ' : ''} 
                                {h.event_name === 'checkout' ? ' check-out ' : ''} 
                                { h.created_at ? dayjs(h.created_at).format(' HH:mm:ss ') : '' }
                                {h.location ? h.location.name + ', ' + h.location.location_name : ''}
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
        
    )
}

export default History