import initialState from './../initialState'
import types from './../actionTypes'

export default (state = initialState.authStore, action = {}) => {

    switch(action.type) {

		case types.AUTH_PENDING: {
			return {
				...state,
				is_pending: true,
				is_success: false
			}
		}

		case types.AUTH_LOGIN_FAIL: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				is_fail: true,
				message: action.payload.message
			}
		}
		
		case types.AUTH_LOGIN: {
			localStorage.setItem("card_token", action.payload.accessToken)
			return {
				...state,
				is_pending: false,
				is_success: true
			}
		}

		case types.AUTH_REGISTER: {
			return {
				...state,
				is_pending: false,
				is_fail: false,
				is_success: true,
			}
		}

		case types.AUTH_REGISTER_FAIL: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				is_fail: true,
				message: action.payload.message
			}
		}

		case types.AUTH_EMAIL_VERIFY: {
			localStorage.setItem("card_token", action.payload.accessToken)
			return {
				...state,
				is_pending: false,
				is_success: true
			}
		}

		case types.GET_CURRENT_USER_SUCCESS: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				user: action.payload.user
			}
		}

        default: 
            return state
    }
}