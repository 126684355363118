import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'

function EmptyLayout({ children }) {
    return (
        <div style={{width: '100%', height: '100%'}}>
            {children}
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLayout)