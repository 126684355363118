import types from './../actionTypes'
import config from './../../config'
import { FetchProfile } from './../../api/profile'
import Profile from './../../models/Profile'
import { AuthEmailLogin, AuthEmailRegister, AuthSocialLogin } from './../../api/auth'
import { VerifyOTP, RequestOTP } from './../../api/otp'
import jwt from "jsonwebtoken"

const authPending = () => ({
    type: types.AUTH_PENDING,
    payload: null
})

export const AuthGetTokenFromLine = (params) => {
    return async (dispatch) => {
		dispatch(authPending())

        try {
            var formParams = {
                grant_type: 'authorization_code',
                code: params.code,
                redirect_uri: config.LINE_REDIRECT_URI,
                client_id: config.LINE_CLIENT_ID,
                client_secret: config.LINE_CLIENT_SECRET
            }

            var formBody = [];
            for (var property in formParams) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(formParams[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }

            formBody = formBody.join("&");

            var request = {
                method: 'POST',
                body: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                }
            }

            let res = await fetch('https://api.line.me/oauth2/v2.1/token', request).then(res => res.json())
            if(res.id_token) {
                let user = jwt.decode(res.id_token)

                let response = await AuthSocialLogin({
                    email: user.email, 
                    provider_id: user.sub, 
                    provider_name: 'line', 
                    name: user.name, 
                    surname: ''
                })

                dispatch({
                    type: types.AUTH_LOGIN,
                    payload: response
                })

            }

            
        } catch(e) {
            // error reading value
        }
    }
}

export const GetProfile = () => {
    return async (dispatch) => {
        dispatch(authPending())
        FetchProfile()
        .then((res) => {
            let user = new Profile(res.user)
            dispatch({
                type: types.GET_CURRENT_USER_SUCCESS,
                payload: { user }
            })
        })
        .catch(() => {

        })
    }
}

export const GetCurrentUser = (callback) => {
	return async (dispatch) => {
		dispatch(authPending())

        FetchProfile()
        .then(res => {
            if(res && res.user && !res.is_setup_success) window.location = '/profile/selfie'
            if(res.error) {
                callback(res, null)
            } else {
                let user = new Profile(res.user)
                dispatch({
                    type: types.GET_CURRENT_USER_SUCCESS,
                    payload: { user }
                })
            }
        })
        .catch(err => {
        
        })
    }
}

export const AuthSocial = (params) => {
    return async (dispatch) => {
        dispatch(authPending())

        try {
            let res = await AuthSocialLogin(params)
            dispatch({
                type: types.AUTH_LOGIN,
                payload: res
            })
        } catch(e) {
            // error reading value
        }
    }
}

export const AuthRegister = (params) => {
    return async (dispatch) => {
        dispatch(authPending())

        try {
            let res = await AuthEmailRegister(params)
            if(res.error) {
                dispatch({
                    type: types.AUTH_REGISTER_FAIL,
                    payload: res
                })
            }
            if(res.success) {
                dispatch({
                    type: types.AUTH_REGISTER,
                    payload: res
                })
            }
            return res
        } catch(err) {
            console.log(err)
        }
    }
}

export const AuthForgotPassword = (params) => {
    return async (dispatch) => {
        dispatch(authPending())

        try {
            let request = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            let res = await fetch(config.BASE_API + '/auth/email/forgotpassword', request).then(res => res.json())
            
            return res
        } catch(err) {
            console.log(err)
        }
    }
}

export const AuthResetVerify = (params) => {
    return async (dispatch) => {
        dispatch(authPending())

        try {
            let request = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            let res = await fetch(config.BASE_API + '/auth/email/forgotpassword/verify', request).then(res => res.json())
            dispatch({
                type: types.AUTH_EMAIL_VERIFY,
                payload: res
            })
            return res
        } catch(err) {
            console.log(err)
        }
    }
}


export const AuthOtpRequest = (params) => {
    return async (dispatch) => {
        dispatch(authPending())

        try {
            let res = await RequestOTP({ via: params.via, to: params.to })
            dispatch({
                type: types.AUTH_OTP_REQUEST,
                payload: res
            })
            return res
        } catch(err) {
            console.log(err)
        }
    }
}

export const AuthOtpVerify = (params) => {
    return async (dispatch) => {
        dispatch(authPending())

        try {
            let res = await VerifyOTP({ via: params.via, to: params.to, code: params.code })
            dispatch({
                type: types.AUTH_OTP_VERIFY,
                payload: res
            })
            return res
        } catch(err) {
            console.log(err)
        }
    }
}

export const AuthEmailVerify = (params) => {
    return async (dispatch) => {
        dispatch(authPending())

        try {
            let request = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            let res = await fetch(config.BASE_API + '/auth/email/verify', request).then(res => res.json())
            dispatch({
                type: types.AUTH_EMAIL_VERIFY,
                payload: res
            })
            return res
        } catch(err) {
            console.log(err)
        }
    }
}

export const AuthLogin = (params) => {
	return async (dispatch) => {
        dispatch(authPending())

        AuthEmailLogin(params)
        .then(res => {
            if(res.accessToken) {
                dispatch({
                    type: types.AUTH_LOGIN,
                    payload: res
                })
            }
            if(res.error) {
                dispatch({
                    type: types.AUTH_LOGIN_FAIL,
                    payload: res
                })
            } 

        })
        .catch(err => {
            console.log(err)
        })
    }
}