import config from '../config'

export const AuthEmailLogin = ({ email, password }) => {
    var request = {
        method: 'POST',
        body: JSON.stringify({ email, password}),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(config.BASE_API + '/auth/email/login', request).then(res => res.json())
}

export const AuthEmailRegister = ({ email, password, password_confirmation, checked }) => {
    let request = {
        method: 'POST',
        body: JSON.stringify({ email, password, password_confirmation, checked}),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(config.BASE_API + '/auth/email/register', request).then(res => res.json())
}


export const AuthSocialLogin = (params) => {
    var request = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(config.BASE_API + '/auth/social/login', request).then(res => res.json())
}

export default { AuthEmailLogin, AuthEmailRegister, AuthSocialLogin }