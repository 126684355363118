import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../../redux/actions'
import Header from './../../components/layout/Header'
import ConfirmForm from './../../components/profile/confirm'
import theme from './../../theme'

function FourthConfirm({ actions, history, information, avatar, idcard, passport, driving_license }) {

    if(!avatar) {
        history.replace('/welcome')
    }

    const onSubmit = () => {
        window.openLoading()
        actions.CreateQRDataGuest(information).then((qrdata) => {
            if(avatar || idcard || passport || driving_license) {
                actions.UploadQRDataGuestAttachment({ email: information.email, avatar, idcard, passport, driving_license })
            }
            if(qrdata) {
                window.closeLoading()
                history.push('/temporary/success')
            }
        })
    }

    return (
        <div style={{height: '100%'}}>
            <Header
                left={
                    <> 
                        <a href="" onClick={(e) => {e.preventDefault();history.replace('/temporary/third')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></a>
                        <a href="" onClick={(e) => {e.preventDefault();history.replace('/temporary/third')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></a>
                    </>
                }
                title={<span className="mui--text-title" style={{color: theme.color.white}}>Create Temporary QR</span>}
                bgColor={theme.color.info}
            />
            <div className="mui-container" style={{padding: '70px 15px 15px 15px'}}>
                <ConfirmForm onSubmit={onSubmit} information={information}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
	return {
        information: state.temporaryStore.information,
        avatar: state.temporaryStore.avatar,
        idcard: state.temporaryStore.idcard,
        passport: state.temporaryStore.passport,
        driving_license: state.temporaryStore.driving_license,
        qrdata: state.qrdataStore.qrdata
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FourthConfirm)
