import React from 'react'
import Stepper from './../common/stepper'
import Button from './../common/button'

const Confirm = ({ onSubmit, information }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }

    return (
        <form onSubmit={handleSubmit} className="mui-form">

            <div className="mui-row">
                <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">

                    <div className="mui-row">
                        <div className="mui-col-xs-12">
                            <Stepper activeStep={3}/>
                        </div>
                    </div>

                    <div className="mui-row mt-3">
                        <div className="mui-col-xs-12">
                            <div className="bg-white p-3">
                                <p style={{fontSize: 16}}><strong>Personal Information</strong></p>

                                <table className="mui-table mui-table--bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan="2"></td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>{information && information.name ? information.name : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Surname</td>
                                            <td>{information && information.surname ? information.surname : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Gender</td>
                                            <td>{information && information.gender ? information.gender : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Birth date</td>
                                            <td>{information && information.birth_date ? information.birth_date : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Nationality</td>
                                            <td>{information && information.nationality ? information.nationality : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{information && information.email ? information.email : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{information && information.phone ? information.phone : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Career</td>
                                            <td>{information && information.career ? information.career : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    <div className="mui-row mt-3">
                        <div className="mui-col-xs-12">
                            <Button bgColor="info" type="submit" label="Confirm"/>
                        </div>
                    </div>

                </div>
            </div>

        </form>
    )
}

export default Confirm