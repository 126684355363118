import types from './../actionTypes'
import config from './../../config'

export const FetchProfile = (callback) => {
	return async (dispatch) => {
		dispatch({
			type: types.PROFILE_PENDING,
			payload: null
        })

        try {
            const auth_token = localStorage.getItem("card_token")
            var request = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth_token}`,
                    'Content-Type': 'application/json'
                }
            };
            fetch(config.BASE_API + '/auth/me', request)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    callback(res, null)
                } else {
                    dispatch({
                        type: types.FETCH_PROFILE,
                        payload: res.user
                    })
                }
            })
            .catch(err => {
            
            })
        } catch(e) {
            // error reading value
        }
    }
}

export const UpdateProfile = (params) => {
    return async (dispatch) => {
		dispatch({
			type: types.PROFILE_PENDING,
			payload: null
        })

        try {
            const auth_token = localStorage.getItem("card_token")
            var request = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Authorization': `Bearer ${auth_token}`,
                    'Content-Type': 'application/json'
                }
            };
            return fetch(config.BASE_API + '/auth/profile', request)
            .then(res => res.json())
            .then(res => {
                dispatch({
                    type: types.UPDATE_PROFILE,
                    payload: res
                })
                return res
            })
            .catch(err => {
            
            })
        } catch(e) {
            // error reading value
        }
    }
}

export const UploadProfileAttachment = (file) => {
    return async (dispatch) => {
        try {
            let formData = new FormData()
            Object.keys(file).forEach(f => {
                if(file[f]) {
                    formData.append(f, file[f])
                }
            })
            let res = await fetch(config.BASE_API + "/auth/profile/attachments" , {
                method: 'POST', 
                body: formData,
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem('card_token'),
                    // 'Content-Type': 'application/json'
                }
            }).then(res => res.json())
            return res
        } catch(err) {
            console.log(err)
        }
    }
}

