import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../../redux/actions'
import Header from './../../components/layout/Header'
import SelfieForm from './../../components/profile/selfie'
import theme from './../../theme'

function FirstProfile({ history, actions }) {

    const onSubmit = (fileAvatar) => {
        if(fileAvatar) {
            actions.SetAvatar(fileAvatar)
            history.push({pathname: '/temporary/second' })
        }
    }

    return (
        <div style={{height: '100%'}}>
            <Header
                left={
                    <> 
                        <a href="" onClick={(e) => {e.preventDefault();history.replace('/welcome')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></a>
                        <a href="" onClick={(e) => {e.preventDefault();history.replace('/welcome')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></a>
                    </>
                }
                title={<span className="mui--text-title" style={{color: theme.color.white}}>Create Temporary QR</span>}
                bgColor={theme.color.info}
            />
            <div className="mui-container" style={{padding: '70px 15px 15px 15px'}}>
                <SelfieForm onSubmit={onSubmit}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstProfile)
