import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import actions from '../../redux/actions'
import Header from './../../components/layout/Header'
import SelfieForm from './../../components/profile/selfie'
import theme from './../../theme'

function FirstProfile({ history }) {

    const dispatch = useDispatch();
	// const user = useSelector(state => state.profileStore.profile, []) || []

    useEffect(() => {
		dispatch(actions.FetchProfile())
    }, [dispatch])

    const onSubmit = (fileAvatar) => {
        if(fileAvatar) {
            dispatch(actions.SetAvatar(fileAvatar))
            history.push({pathname: '/profile/information' })
        }
    }

    const signOut = () => {
        localStorage.removeItem('card_token')
        window.location = '/welcome'
    }

    return (
        
        <div style={{height: '100%'}}>
            <Header
                left={
                    <>
                        <button type="button" onClick={(e) => {e.preventDefault();signOut()}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-sign-out"></i></button>
                        <button type="button" onClick={(e) => {e.preventDefault();signOut()}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-sign-out"></i></button>
                    </>
                }
                title={<span className="mui--text-title" style={{color: theme.color.white}}>CREATE PROFILE</span>}
                bgColor={theme.color.info}
            />
            <div className="mui-container" style={{padding: '70px 15px 15px 15px'}}>
                <SelfieForm onSubmit={onSubmit} />
            </div>
        </div>
    )
}

export default FirstProfile
