import types from './../actionTypes'
import { FetchHistoryApi, CreateHistoryApi } from './../../api/histories'

const historyPending = () => ({
    type: types.HISTORY_PENDING,
    payload: null
})

export const FetchHistories = () => {
	return async (dispatch) => {
		dispatch(historyPending())

        try {
            let res = await FetchHistoryApi()
            dispatch({
                type: types.FETCH_HISTORY,
                payload: res
            })
        } catch(e) {
            // error reading value
        }
    }
}

export const CreateHistory = (params) => {
    return async (dispatch) => {
		dispatch(historyPending())

        try {
            let res = await CreateHistoryApi(params)
            dispatch({
                type: types.CREATE_HISTORY,
                payload: res
            })
            return res
        } catch(e) {
            // error reading value
        }
    }
}