import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from "yup"
import { useAlert } from "react-alert"
import actions from './../redux/actions'
import config from './../config'
import InputText from './../components/form/InputText'
import Button from './../components/common/button'
import styled from 'styled-components'

const ImageFile = ({elementId, fileb, attachment, type}) => {

    if(fileb) 
        return <ImageView onClick={() => document.getElementById(elementId).click()} src={URL.createObjectURL(fileb)} alt=""/>

    if(attachment && attachment[type] && attachment[type].disk_name) 
        return <ImageView onClick={() => document.getElementById(elementId).click()} src={config.BASE_API + '/' + attachment[type].disk_name + '?accessToken=' + localStorage.getItem('card_token')} alt=""/>

    return <ImageThumb className="d-flex" onClick={() => document.getElementById(elementId).click()}><i className="fa fa-picture-o"></i></ImageThumb>    
}

function Profile() {
    const alert = useAlert()
    let [fileAvatar, setFileAvatar] = useState(null)
    let [fileIDcard, setFileIDcard] = useState(null)
    let [filePassport, setFilePassport] = useState(null)
    let [fileDrivingLicense, setFileDrivingLicense] = useState(null)

    const dispatch = useDispatch();
    const profile = useSelector(state => state.profileStore.profile, {}) || {}
    
    useEffect(() => {
		dispatch(actions.FetchProfile())
    }, [dispatch])

    const onSubmit = async (values) => {
        dispatch(actions.UpdateProfile(values))
        if(fileAvatar || fileIDcard || filePassport || fileDrivingLicense) {
            let res = await dispatch(actions.UploadProfileAttachment({avatar: fileAvatar, idcard: fileIDcard, passport: filePassport, driving_license: fileDrivingLicense}))
        }
        alert.success("Profile updated.")
    }

    return (
        <Formik 
            initialValues={profile.information ? profile.information : {}}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    name: Yup.mixed().required().label('Name'),
                    surname: Yup.mixed().required().label('Surname'),
                    email: Yup.string().required().label('Email'),
                    phone: Yup.mixed().required().label('Mobile number'),
                })
            }
        >
            {
                ({ values, handleSubmit, handleChange, errors }) => {
                    return (
                        <form className="mui-form" onSubmit={handleSubmit}>
                            <div className="mui-row">
                                <div className="mui-col-xs-12 mui--text-center">
                                    {/* {profile.attachment && profile.attachment.avatar && profile.attachment.avatar.disk_name ? <img src={config.BASE_API+'/'+profile.attachment.avatar.disk_name} style={{width: 120}} alt=""/> : <img src="/images/as.png" style={{width: 120}} alt=""/>} */}
                                    <input type="file" id="fileAvatar" style={{display: 'none'}} onChange={(e) => setFileAvatar(e.target.files[0])}/>
                                    <div onClick={() => document.getElementById('fileAvatar').click()}>
                                        { fileAvatar ? 
                                                <img src={URL.createObjectURL(fileAvatar)} style={{width: 120}} alt=""/> 
                                            : 
                                            (
                                                profile.attachment && profile.attachment.avatar && profile.attachment.avatar.disk_name ?
                                                    <img src={config.BASE_API + '/' + profile.attachment.avatar.disk_name + '?accessToken=' + localStorage.getItem('card_token')} style={{borderRadius: 60, width: 120, height: 120, objectFit: 'cover'}} alt=""/>
                                                : <img src="/images/as.png" style={{width: 120}} alt=""/>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mui-row" style={{marginTop: 20, backgroundColor: '#fff'}}>
                                <div className="mui-col-xs-12" style={{padding: 20}}>
                                    <p style={{fontSize: 16}}><strong>Personal Information</strong></p>
                                    
                                    <div className="mui-row">
                                        <div className="mui-col-xs-6">
                                            <InputText name="name" label="Name" onChange={handleChange} value={values.name || ''}/>
                                        </div>
                                        <div className="mui-col-xs-6">
                                            <InputText name="surname" label="Surname" onChange={handleChange} value={values.surname || ''}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-6">
                                            <InputText noFloat type="date" name="birth_date" label="Birth date" onChange={handleChange} value={values.birth_date || ''}/>
                                        </div>
                                        <div className="mui-col-xs-6">
                                            <InputText name="nationality" label="Nationality" onChange={handleChange} value={values.nationality || ''}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12">
                                            <InputText type="email" name="email" label="Email" onChange={handleChange} value={values.email || ''}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12">
                                            <InputText name="phone" label="Mobile number" onChange={handleChange} value={values.phone || ''}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12 d-flex">
                                            <div className="flex1">
                                                <InputText name="citizen_id" label="iD number" onChange={handleChange} value={values.citizen_id || ''}/>
                                            </div>
                                            <input type="file" id="fileIdcard" style={{display: 'none'}} onChange={(e) => setFileIDcard(e.target.files[0])}/>
                                            <ImageFile elementId="fileIdcard" type="idcard" fileb={fileIDcard} attachment={profile.attachment}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12 d-flex">
                                            <div className="flex1">
                                                <InputText name="passport_id" label="Passport number" onChange={handleChange} value={values.passport_id || ''}/>
                                            </div>
                                            <input type="file" id="filePassport" style={{display: 'none'}} onChange={(e) => setFilePassport(e.target.files[0])}/>
                                            <ImageFile elementId="filePassport" type="passport" fileb={filePassport} attachment={profile.attachment}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-xs-12 d-flex">
                                            <div className="flex1">
                                                <InputText name="driving_license_id" label="Driving license number" onChange={handleChange} value={values.driving_license_id || ''}/>
                                            </div>
                                            <input type="file" id="fileDrivingLicense" style={{display: 'none'}} onChange={(e) => setFileDrivingLicense(e.target.files[0])}/>
                                            <ImageFile elementId="fileDrivingLicense" type="driving_license" fileb={fileDrivingLicense} attachment={profile.attachment}/>
                                        </div>
                                    </div>

                                    <div className="mui-row">
                                        <div className="mui-col-md-12">
                                            <Button bgColor="info" type="submit" label="Save"/>
                                        </div>
                                    </div>
                                    <div className="mui-row">
                                        <div className="mui-col-md-12">
                                            <Link id="btnBack" to="/" style={{display: 'none'}}></Link>
                                            <Button bgColor="grey" type="button" onClick={() => document.getElementById('btnBack').click()} label="Cancel"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                }
            }
        </Formik>
    )
}

const ImageThumb = styled.div`
    margin-left: 10px; 
    width: 100px;
    height: 100px; 
    border: 1px solid grey;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;

const ImageView = styled.img`
    margin-left: 10px; 
    width: 100px;
    height: 100px; 
    margin-bottom: 10px;
    object-fit: cover;
`;

export default Profile
