export default class Profile {
    constructor(props = {}) {
        this.jcid = props.jcid || null;
        this.email = props.email || null;
        this.kyc_level = props.kyc_level || null;
        this.card_status = props.card_status || null;
        this.email_verify = props.email_verify || null;
        this.phone_verify = props.phone_verify || null;
        this.information = new Information(props.information || {});
        this.attachment = new Attachment(props.attachment || {});
    }
}

export class Information {
    constructor(props = {}) {
        this.name = props.name || null;
        this.surname = props.surname || null;
        this.birth_date = props.birth_date || null;
        this.email = props.email || null;
        this.phone = props.phone || null;
        this.dial_code = props.dial_code || '';
        this.nationality = props.nationality || null;
        this.gender = props.gender || null;
        this.career = props.career || null;
        this.citizen_id = props.citizen_id || null;
        this.passport_id = props.passport_id || null;
        this.driving_license_id = props.driving_license_id || null;
        this.notes = props.notes || null;
        this.place_to = props.place_to || null;
        this.kyc_level = props.kyc_level || null;
        this.attachment = props.attachment || {};
    }

}

class Attachment {
    constructor(props = {}) {
        this.avatar = props.avatar || {};
        this.idcard = props.idcard || {};
        this.passport = props.passport || {};
        this.driving_license = props.driving_license || {};
    }
}