import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../../redux/actions'
import Header from './../../components/layout/Header'
import ConfirmForm from './../../components/profile/confirm'
import theme from './../../theme'

function ProfileConfirm({ actions, history, user, information, avatar, idcard, passport, driving_license }) {

    if(!avatar) {
        history.replace('/profile/selfie')
    }

    const onSubmit = () => {
        window.openLoading()
        actions.UpdateProfile(information).then(() => {
            if(avatar || idcard || passport || driving_license) {
                actions.UploadProfileAttachment({avatar, idcard, passport, driving_license}).then(() => {
                    window.closeLoading()
                    window.location = '/home'
                })
            } else {
                window.closeLoading()
                window.location = '/home'
            }
        })
    }

    return (
        <div style={{height: '100%'}}>
            <Header
                left={
                    <> 
                        <button type="button" onClick={(e) => {e.preventDefault();history.replace('/profile/attachment')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                        <button type="button" onClick={(e) => {e.preventDefault();history.replace('/profile/attachment')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                    </>
                }
                title={<span className="mui--text-title" style={{color: theme.color.white}}>CREATE PROFILE</span>}
                bgColor={theme.color.info}
            />
            <div className="mui-container" style={{padding: '70px 15px 15px 15px'}}>
                <ConfirmForm onSubmit={onSubmit} information={information}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
	return {
        information: state.temporaryStore.information,
        avatar: state.temporaryStore.avatar,
        idcard: state.temporaryStore.idcard,
        passport: state.temporaryStore.passport,
        driving_license: state.temporaryStore.driving_license,
        qrdata: state.qrdataStore.qrdata,
        user: state.profileStore.profile
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileConfirm)
