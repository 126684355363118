import React, {useState}  from 'react'
import Stepper from './../common/stepper'
import Button from './../common/button'

const Selfie = ({ onSubmit }) => {
    let [fileAvatar, setFileAvatar] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit(fileAvatar)
    }

    return (
        <form onSubmit={handleSubmit} className="mui-form">

            <div className="mui-row">
                <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">

                    <div className="mui-row">
                        <div className="mui-col-xs-12">
                            <Stepper activeStep={0}/>
                        </div>
                    </div>

                    <div className="mui-row mt-3">
                        <div className="mui-col-xs-12">
                            <div className="bg-white p-3">
                                <div className="mui-row mt-5" style={{margin: 5, borderRadius: 5, border: '2px solid rgb(0, 0, 0, 0.15)'}}>
                                    <div className="mui-col-xs-12 d-flex p-3">
                                        <div style={{ flex: 0 }}>
                                            <input type="file" id="fileAvatar" accept=".png,.jpg,.jpeg" capture="user" style={{display: 'none'}} onChange={(e) => setFileAvatar(e.target.files[0])}/>
                                            <img src="/images/as.png" style={{width: 90, paddingRight: 15}} alt=""/>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <p style={{fontSize: 16}}><strong>Upload selfie photo.</strong></p>
                                            <span className="d-block">- Only png, jpg, jpeg files.</span>
                                            <Button outlined full={false} className="mui-btn mui-btn--flat mui-btn--primary" type="button" onClick={() => document.getElementById('fileAvatar').click()} label="Upload"/>
                                        </div>
                                    </div>
                                </div>
                                { fileAvatar ? 
                                    <div className="mui-row mt-4">
                                        <div className="mui-col-xs-12 mui--text-center">
                                            <img src={URL.createObjectURL(fileAvatar)} style={{width: 200, height: 200, objectFit: 'cover'}} alt=""/> 
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>

                    <div className="mui-row mt-4">
                        <div className="mui-col-xs-12">
                            <div className="mui-row mt-3">
                                <div className="mui-col-md-12">
                                    <Button bgColor="info" type="submit" label="Next" disabled={fileAvatar ? false : true}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </form>
    )
}

export default Selfie