import React from 'react'
import PropTypes from 'prop-types'

function Header({left, right, title, bgColor}) {
    return (
        <header id="header" style={{left: 0, backgroundColor: bgColor}}>
            <div className="mui-appbar mui--appbar-line-height">
                <div className="mui-container-fluid d-flex" style={{justifyContent: 'space-between'}}>
                    <div className="flex0" style={{width: 30}}>
                        { left }
                    </div>
                    <div className="flex1 mui--text-center">
                        { title }
                    </div>
                    <div className="flex0" style={{width: 30}}>
                        { right }
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    title: PropTypes.element,
    left: PropTypes.element,
    right: PropTypes.element,
    bgColor: PropTypes.string
}

export default Header