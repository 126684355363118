import initialState from './../initialState'
import types from './../actionTypes'

export default (state = initialState.profileStore, action = {}) => {

    switch(action.type) {

		case types.PROFILE_PENDING: {
			return {
				...state,
				is_pending: true,
				is_success: false
			}
		}

		case types.FETCH_PROFILE: {
			return {
				...state,
				is_pending: false,
				is_success: false,
				profile: action.payload
			}
        }
        
        case types.UPDATE_PROFILE: {
            console.log(action.payload)
			return {
				...state,
				is_pending: false,
				is_success: true,
			}
		}

        default: 
            return state
    }
}