import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import Stepper from './../common/stepper'
import Button from './../common/button'
import InputText from './../form/InputText'

const Corner = styled.div`
    width: 140px;
    height: 100px;

    background:
        linear-gradient(to right, black 4px, transparent 4px) 0 0,
        linear-gradient(to right, black 4px, transparent 4px) 0 100%,
        linear-gradient(to left, black 4px, transparent 4px) 100% 0,
        linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
        linear-gradient(to top, black 4px, transparent 4px) 0 100%,
        linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
`;

const ThumbBox = styled.div`
    margin: 5px;
    border-radius: 5px;
    border: 2px solid rgb(0, 0, 0, 0.15);
`;

const Attachment = ({ onSubmit }) => {
    let [selected, setSelected] = useState('idcard')
    let [fileIDcard, setFileIDcard] = useState(null)
    let [filePassport, setFilePassport] = useState(null)
    let [fileDrivingLicense, setFileDrivingLicense] = useState(null)

    return (
        <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={(values) => {
                onSubmit(values, { idcard: fileIDcard, passport: filePassport, driving_license: fileDrivingLicense } )
            }}
            validationSchema={
                Yup.object().shape({
                    citizen_id: Yup.string(),
                    passport_id: Yup.string(),
                    driving_license_id: Yup.string(),
                })
            }
        >
            {({handleSubmit, handleChange, values}) => {
                return (
                    <form onSubmit={handleSubmit} className="mui-form">
                        <div className="mui-row">
                            <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                            <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">

                                <div className="mui-row">
                                    <div className="mui-col-xs-12">
                                        <Stepper activeStep={2}/>
                                    </div>
                                </div>

                                <div className="mui-row">
                                    <div className="mui-col-xs-12 mui--text-center d-flex">
                                        <div style={{flex: 1}}>
                                            <button onClick={() => setSelected('idcard')} type="button" className={`mui-btn ${selected === 'idcard' ? 'mui-btn--primary' : ''}`} style={{borderBottomRightRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 20, borderTopLeftRadius: 20, width: '100%'}}>ID Card</button>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <button onClick={() => setSelected('passport')} type="button" className={`mui-btn ${selected === 'passport' ? 'mui-btn--primary' : ''}`} style={{borderRadius: 0, width: '100%'}}>Passport</button>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <button onClick={() => setSelected('driving_license')} type="button" className={`mui-btn ${selected === 'driving_license' ? 'mui-btn--primary' : ''}`} style={{borderBottomRightRadius: 20, borderTopRightRadius: 20, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, width: '100%'}}>Driving</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="mui-row mt-3">
                                    <div className="mui-col-xs-12">
                                        <div className="bg-white p-3">
                                            {
                                                selected === 'idcard' ? 
                                                    <div className="mui-row" >
                                                        <div className="mui-col-xs-12">

                                                            <ThumbBox className="mui-row mt-5">
                                                                <div className="mui-col-xs-12 d-flex p-3">
                                                                    <div style={{ flex: 0 }}>
                                                                        <Corner>
                                                                            <input type="file" id="fileIdcard" accept=".png,.jpg,.jpeg" style={{display: 'none'}} onChange={(e) => setFileIDcard(e.target.files[0])}/>
                                                                            <img src="/images/ID-CARD.png" style={{width: 110, margin: 15}} alt=""/>
                                                                        </Corner>
                                                                    </div>
                                                                    <div style={{ flex: 1, paddingLeft: 15 }}>
                                                                        <p style={{fontSize: 16}}><strong>Upload your ID Card.</strong></p>
                                                                        <span className="d-block">- Only png, jpg, jpeg files.</span>
                                                                        <Button outlined full={false} className="mui-btn mui-btn--flat mui-btn--primary" type="button" onClick={() => document.getElementById('fileIdcard').click()} label="Upload"/>
                                                                    </div>
                                                                </div>
                                                            </ThumbBox>

                                                            { fileIDcard ? 
                                                                <div className="mui-row mt-4">
                                                                    <div className="mui-col-xs-12 mui--text-center">
                                                                        <img src={URL.createObjectURL(fileIDcard)} style={{width: 200, height: 170, objectFit: 'cover'}} alt=""/> 
                                                                    </div>
                                                                </div>
                                                            : null}

                                                            <div className="mui-row">
                                                                <div className="mui-col-xs-12">
                                                                    <InputText name="citizen_id" label="ID number" onChange={handleChange} value={values.citizen_id || ''} required/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null
                                            }

                                            {
                                                selected === 'passport' ? 
                                                    <div className="mui-row">
                                                        <div className="mui-col-xs-12">
                                                            <ThumbBox className="mui-row mt-5">
                                                                <div className="mui-col-xs-12 d-flex p-3">
                                                                    <div style={{ flex: 0 }}>
                                                                        <Corner>
                                                                            <input type="file" id="filePassport" accept=".png,.jpg,.jpeg" style={{display: 'none'}} onChange={(e) => setFilePassport(e.target.files[0])}/>
                                                                            <img src="/images/Passport.png" style={{width: 110, margin: 15}} alt=""/>
                                                                        </Corner>
                                                                    </div>
                                                                    <div style={{ flex: 1, paddingLeft: 15 }}>
                                                                        <p style={{fontSize: 16}}><strong>Upload your Passport.</strong></p>
                                                                        <span className="d-block">- Only png, jpg, jpeg files.</span>
                                                                        <Button outlined full={false} className="mui-btn mui-btn--flat mui-btn--primary" type="button" onClick={() => document.getElementById('filePassport').click()} label="Upload"/>
                                                                    </div>
                                                                </div>
                                                            </ThumbBox>

                                                            { filePassport ? 
                                                                <div className="mui-row mt-4">
                                                                    <div className="mui-col-xs-12 mui--text-center">
                                                                        <img src={URL.createObjectURL(filePassport)} style={{width: 200, height: 170, objectFit: 'cover'}} alt=""/> 
                                                                    </div>
                                                                </div>
                                                            : null}

                                                            <div className="mui-row">
                                                                <div className="mui-col-xs-12">
                                                                    <InputText name="passport_id" label="Passport number" onChange={handleChange} value={values.passport_id || ''} required/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null
                                            }

                                            {
                                                selected === 'driving_license' ? 
                                                    <div className="mui-row">
                                                        <div className="mui-col-xs-12">
                                                            <ThumbBox className="mui-row mt-5">
                                                                <div className="mui-col-xs-12 d-flex p-3">
                                                                    <div style={{ flex: 0 }}>
                                                                        <Corner>
                                                                            <input type="file" id="fileDrivingLicense" accept=".png,.jpg,.jpeg" style={{display: 'none'}} onChange={(e) => setFileDrivingLicense(e.target.files[0])}/>
                                                                            <img src="/images/DriverLicense.png" style={{width: 110, margin: 15}} alt=""/>
                                                                        </Corner>
                                                                    </div>
                                                                    <div style={{ flex: 1, paddingLeft: 15 }}>
                                                                        <p style={{fontSize: 16}}><strong>Upload your Driving license.</strong></p>
                                                                        <span className="d-block">- Only png, jpg, jpeg files.</span>
                                                                        <Button outlined full={false} className="mui-btn mui-btn--flat mui-btn--primary" type="button" onClick={() => document.getElementById('fileDrivingLicense').click()} label="Upload"/>
                                                                    </div>
                                                                </div>
                                                            </ThumbBox>

                                                            { fileDrivingLicense ? 
                                                                <div className="mui-row mt-4">
                                                                    <div className="mui-col-xs-12 mui--text-center">
                                                                        <img src={URL.createObjectURL(fileDrivingLicense)} style={{width: 200, height: 170, objectFit: 'cover'}} alt=""/> 
                                                                    </div>
                                                                </div>
                                                            : null}

                                                            <div className="mui-row">
                                                                <div className="mui-col-xs-12">
                                                                    <InputText name="driving_license_id" label="Driving license number" onChange={handleChange} value={values.driving_license_id || ''} required/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="mui-row">
                                    <div className="mui-col-xs-12">
                                        <div className="mui-row mt-3">
                                            <div className="mui-col-md-12">
                                                <Button bgColor="info" type="submit" label="Next" disabled={(fileIDcard || filePassport || fileDrivingLicense) && (values.citizen_id || values.passport_id || values.driving_license_id) ? false : true}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </form>
                )
            }}
        </Formik>
    )
}

export default Attachment