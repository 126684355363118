import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../../redux/actions'
import Header from './../../components/layout/Header'
import InformationForm from './../../components/profile/information'
import theme from './../../theme'

function ProfileInformation({ actions, avatar, history, user }) {

    if(!avatar) {
        history.replace('/profile/selfie')
    }

    const onSubmit = (values) => {
        if(values.checked) {
            actions.SetInformation(values)
            history.push({pathname: '/profile/attachment' })
        }
    }

    return (
        <div style={{height: '100%'}}>
            <Header
                left={
                    <> 
                        <a href="" onClick={(e) => {e.preventDefault();history.replace('/profile/selfie')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></a>
                        <a href="" onClick={(e) => {e.preventDefault();history.replace('/profile/selfie')}} style={{color: theme.color.white}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></a>
                    </>
                }
                title={<span className="mui--text-title" style={{color: theme.color.white}}>CREATE PROFILE</span>}
                bgColor={theme.color.info}
            />
            <div className="mui-container" style={{padding: '70px 15px 15px 15px'}}>
                <InformationForm onSubmit={onSubmit} initialValues={user && user.information}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
	return {
        user: state.profileStore.profile,
        avatar: state.temporaryStore.avatar,
        information: state.temporaryStore.information
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInformation)
