import config from '../config'

export const RequestOTP = ({ via, to }) => {
    const auth_token = localStorage.getItem("card_token")
    var request = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ via, to })
    };
    return fetch(config.BASE_API + '/auth/otp/request', request).then(res => res.json())
}

export const VerifyOTP = ({ via, to, code }) => {
    const auth_token = localStorage.getItem("card_token")
    var request = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ via, to, code })
    };
    return fetch(config.BASE_API + '/auth/otp/verify', request).then(res => res.json())
}

export default { RequestOTP, VerifyOTP }