import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../../redux/actions'

function QRLifeTime({setting, actions}) {

    const handleChange = (age) => {
        actions.UpdateSetting({...setting, qr_age: age}).then(() => {
            actions.FetchSetting()
        })
    }

    return (
           
           <div className="mui-row" style={{marginTop: 20, backgroundColor: '#fff'}}>
               <div className="mui-col-xs-12" style={{padding: 20}}>

                    <div onClick={() => handleChange('15')} className="d-flex" style={{paddingBottom: 20, borderBottom: '1px solid lightgrey'}}>
                        <div className="flex1">
                            15 mins
                        </div>
                        <div className="mui--text-right" style={{ width: 30 }}>
                            {setting && setting.qr_age && setting.qr_age === '15' && <i className="fa fa-check"></i>}
                        </div>
                    </div>

                    <div onClick={() => handleChange('120')} className="d-flex" style={{paddingTop: 20, paddingBottom: 20, borderBottom: '1px solid lightgrey'}}>
                        <div className="flex1">
                            2 hours
                        </div>
                        <div className="mui--text-right" style={{ width: 30 }}>
                            {setting && setting.qr_age && setting.qr_age === '120' && <i className="fa fa-check"></i>}
                        </div>
                    </div>

                    <div onClick={() => handleChange('1440')} className="d-flex" style={{paddingTop: 20, paddingBottom: 20, borderBottom: '1px solid lightgrey'}}>
                        <div className="flex1">
                            24 hours
                        </div>
                        <div className="mui--text-right" style={{ width: 30 }}>
                            {setting && setting.qr_age && setting.qr_age === '1440' && <i className="fa fa-check"></i>}
                        </div>
                    </div>

                    <div onClick={() => handleChange('21600')} className="d-flex" style={{paddingTop: 20}}>
                        <div className="flex1">
                            15 days
                        </div>
                        <div className="mui--text-right" style={{ width: 30 }}>
                            {setting && setting.qr_age && setting.qr_age === '21600' && <i className="fa fa-check"></i>}
                        </div>
                    </div>
               </div>
           </div>
    )
}




const mapStateToProps = (state, ownProps) => {
	return {
        setting: state.settingStore.setting
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QRLifeTime)
