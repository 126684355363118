import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../../redux/actions'
// import styled from 'styled-components'

function AppInfo() {

    return (
           
        <div className="mui-row" style={{marginTop: 20}}>
            <div className="mui-col-xs-12 mui--text-center" style={{padding: 20}}>
                <div style={{display: 'inline-block', width: 120, height: 120, backgroundColor: '#fff'}}>
                    <img src="/images/card_icon.png" style={{width: 120}} alt=""/>
                </div>
                <br/>
                <span>Just-iD Card v1.2.5</span>
            </div>
        </div>
    )
}




const mapStateToProps = (state, ownProps) => {
	return {
        setting: state.settingStore.setting
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppInfo)
