import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../redux/actions'
import { Formik } from 'formik'
import * as Yup from 'yup'

function VerifyEmail({actions, location, history}) {
    
    if(location && location.state && location.state.email) {
        const onSubmit = async (values) => {
            let res = await actions.AuthResetVerify({email: location.state.email, code: values.code})
            if(res && res.accessToken) {
                history.replace('/')
            }
        }
        return (
            <Formik
                initialValues={{}}
                onSubmit={(values) => onSubmit(values)}
                validationSchema={
                    Yup.object().shape({
                        code: Yup.string().length(6).required().label('Reset password code'),
                    })
                }
            >
                {({handleSubmit, handleChange, values}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="mui--text-center">
                                For reset password, a reset password code has been sent to your email address
                            </div>
                            <div className="mui--text-center mt-2">
                                <strong>{location.state.email}</strong>
                            </div>
                
                            <div className="mui-textfield mui-textfield--float-label mui--text-center mt-2">
                                <input type="text" className="mui--text-center" name="code" value={values.code} onChange={handleChange}/>
                                <label>Enter reset password code</label>
                            </div>
                
                            <div className="mui--text-center">
                                <button className="mui-btn btn-full mui-btn--primary" type="submit" style={{borderRadius: 20}}>Confirm</button>
                            </div>
                
                            <div className="mui--text-right mt-3">
                                Haven't received your code yet? <a href="">Click here</a> to resend.
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    } else {
        history.replace('/auth/register')
    }
}


const mapStateToProps = (state, ownProps) => {
	return {
        user: state.authStore.user
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
