
export default {
    // Auth
    AUTH_PENDING: "AUTH_PENDING",
    AUTH_LOGIN: "AUTH_LOGIN",
    AUTH_LOGIN_FAIL: "AUTH_LOGIN_FAIL",
    AUTH_REGISTER: "AUTH_REGISTER",
    AUTH_REGISTER_FAIL: "AUTH_REGISTER_FAIL",
    AUTH_EMAIL_VERIFY: "AUTH_EMAIL_VERIFY",
    AUTH_OTP_REQUEST: "AUTH_OTP_REQUEST",
    AUTH_OTP_VERIFY: "AUTH_OTP_VERIFY",
    GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
    // Setting
    SETTING_PENDING: "SETTING_PENDING",
    FETCH_SETTING: "FETCH_SETTING",
    UPDATE_SETTING: "UPDATE_SETTING",
    // Profile
    PROFILE_PENDING: "PROFILE_PENDING",
    FETCH_PROFILE: "FETCH_PROFILE",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    // QRData
    QRDATA_PENDING: "QRDATA_PENDING",
    CREATE_QRDATA: "CREATE_QRDATA",
    // Temporary
    SET_AVATAR: "SET_AVATAR",
    SET_INFORMATION: "SET_INFORMATION",
    SET_ATTACHMENT: "SET_ATTACHMENT",
    SET_INFORMATION_ATTACHMENT: "SET_INFORMATION_ATTACHMENT",
    // History
    HISTORY_PENDING: "HISTORY_PENDING",
    FETCH_HISTORY: "FETCH_HISTORY",
    CREATE_HISTORY: "CREATE_HISTORY"
}