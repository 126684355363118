import React from 'react'
import {Route, Link} from 'react-router-dom'
import { Router } from 'react-router'
import {createBrowserHistory} from 'history'
import { Provider } from 'react-redux'
import { positions, Provider as AlertProvider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import configureStore from './redux/configureStore'
import MainLayout from './components/MainLayout'
import MainBackLayout from './components/MainBackLayout'
import EmptyLayout from './components/EmptyLayout'
// import NotFound from './pages/NotFound'
import Main from './pages/Main'
// import Loading from './pages/Loading'
import Login from './pages/Login'
import Welcome from './pages/Welcome'
import History from './pages/History'
import Setting from './pages/Setting'
import SettingQRLifeTime from './pages/setting/QRLifeTime'
import SettingAppInfo from './pages/setting/AppInfo'
import Profile from './pages/Profile'
import ProfileSelfie from './pages/profile/Selfie'
import ProfileInformation from './pages/profile/Information'
import ProfileAttachment from './pages/profile/Attachment'
import ProfileConfirm from './pages/profile/Confirm'
import Register from './pages/Register'
import ForgotPassword from './pages/ForgotPassword'
import RequestQR from './pages/RequestQR'
import TemporaryQR from './pages/TemporaryQR'
import TemporaryQRData from './pages/TemporaryQRData'
import LineCallback from './pages/LineCallback'
import VerifyReset from './pages/VerifyReset'
import FirstProfile from './pages/temporary/FirstProfile'
import SecondInformation from './pages/temporary/SecondInformation'
import ThirdAttachment from './pages/temporary/ThirdAttachment'
import FourthConfirm from './pages/temporary/FourthConfirm'
import VerifyPhone from './pages/VerifyPhone'
import VerifyEmail from './pages/VerifyEmail'


const history = createBrowserHistory()

const store = configureStore()

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route {...rest} render={props => (
		<Layout {...rest} {...props}>
			<Component {...props} />
		</Layout>
	)} />
)

const logoTitle = <img src="/images/card_logo_land_new.png" style={{marginTop: 5, height: 20}} alt=""/>
const homeRightAction = <>
	<Link to="/profile" className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block"><i className="fa fa-user"></i></Link>
	<Link to="/profile" className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm"><i className="fa fa-user"></i></Link>
</>

const options = {
	timeout: 5000,
	position: positions.TOP_CENTER
}

function App() {

	return (
		<Provider store={store}>
			<AlertProvider template={AlertTemplate} {...options}>
				<Router history={history}>
					<Route exact title="Create Temporary QR" path="/temporary/first" component={FirstProfile}/>
					<Route exact title="Create Temporary QR" path="/temporary/second" component={SecondInformation}/>
					<Route exact title="Create Temporary QR" path="/temporary/third" component={ThirdAttachment}/>
					<Route exact title="Create Temporary QR" path="/temporary/confirm" component={FourthConfirm}/>
					<Route exact title="Create Temporary QR" path="/temporary/success" component={TemporaryQRData}/>

					<Route exact title="Create Profile" path="/profile/selfie" component={ProfileSelfie}/>
					<Route exact title="Create Profile" path="/profile/information" component={ProfileInformation}/>
					<Route exact title="Create Profile" path="/profile/attachment" component={ProfileAttachment}/>
					<Route exact title="Create Profile" path="/profile/confirm" component={ProfileConfirm}/>

					<Route exact title="Phone Verification" path="/verify/phone" component={VerifyPhone}/>
					<Route exact title="Email Verification" path="/verify/email" component={VerifyEmail}/>

					<AppRoute exact layout={EmptyLayout} title="WELCOME" path="/welcome" component={Welcome}/>
					<AppRoute exact layout={EmptyLayout} title="LOGIN" path="/auth/login" component={Login}/>
					<AppRoute exact layout={EmptyLayout} title="Line" path="/line-callback" component={LineCallback}/>
					<AppRoute exact layout={MainBackLayout} backPath="/auth/login" title="REGISTER" path="/auth/register" component={Register}/>
					<AppRoute exact path="/forgotpassword/verify" title="VERIFY RESET PASSWORD" layout={MainBackLayout} backPath="/forgotpassword"  component={VerifyReset}/>
					<AppRoute exact path="/forgotpassword" title="FORGOT PASSWORD" layout={MainBackLayout} backPath="/auth/login"  component={ForgotPassword}/>
					<AppRoute exact layout={EmptyLayout} backPath="/temp-qr" path="/temp-qr/qr" component={TemporaryQRData}/>
					<AppRoute exact layout={MainBackLayout} backPath="/welcome" title="Create Temporary QR" path="/temp-qr" component={TemporaryQR}/>
					<AppRoute exact path="/profile" title="PROFILE" backPath="/" layout={MainBackLayout} component={Profile}/>
					<AppRoute exact path="/setting/appinfo" title="App Info" backPath="/setting" layout={MainBackLayout} component={SettingAppInfo}/>
					<AppRoute exact path="/setting/qr" title="QR Life Time" backPath="/setting" layout={MainBackLayout} component={SettingQRLifeTime}/>
					<AppRoute exact path="/setting" title="SETTING" layout={MainLayout} component={Setting}/>
					<AppRoute exact path="/history" title="HISTORY" layout={MainLayout} component={History} />
					<AppRoute exact path="/requestqr" layout={EmptyLayout} title="LOGIN" component={RequestQR}/>
					<AppRoute exact path="/home" title={logoTitle} rightAction={homeRightAction} layout={MainLayout} component={Main} />
					<AppRoute exact path="/" title={logoTitle} rightAction={homeRightAction} layout={MainLayout} component={Main} />
						{/* <Route exact path="*" component={NotFound}/> */}
						{/* <Route path="/quarantine" name="Quarantine" component={Quarantine}/> */}
						{/* <AppRoute exact path="/" layout={MainLayout} component={Main} /> */}
				</Router>
			</AlertProvider>
		</Provider>
	)
}

export default App