import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import swal from 'sweetalert'
import actions from '../redux/actions'
import Header from './../components/layout/Header'
import theme from './../theme'
import OTPVerifyForm from './../components/OTPVerifyForm'

function VerifyEmail({ history, location }) {

    const dispatch = useDispatch();
    const user = useSelector(state => state.authStore.user, {}) || {}

    useEffect(() => {
        if(user?.information?.email) {
            dispatch(actions.AuthOtpRequest({ via: 'email', to: user?.information?.email}))
        }
    }, [dispatch, user])

    const onSubmit = (values) => {
        dispatch(actions.AuthOtpVerify({ via: 'email', to: user?.information?.email, code: values.code })).then(res => {

            if(res.error) {
                swal("Verify failed", res.message, "error");
            }
            if(res.success) {
                swal("Verify Success", res.message, "success")
                .then(() => {
                    window.location = '/'
                })
            }
        })
    }

    const resendOTP = () => {
        if(user?.information?.phone) {
            dispatch(actions.AuthOtpRequest({ via: 'sms', to: user?.information?.phone}))
        }
    }

    const goBack = () => {
        if(location?.state?.prevPath) {
            history.push(location?.state?.prevPath)
        } else {
            history.replace('/')
        }
    }

    return (
        <div style={{height: '100%'}}>
            <Header
                left={
                    <> 
                        <button type="button" onClick={() => goBack()} style={{color: theme.color.white}} className="sidedrawer-toggle mui--visible-xs-inline-block mui--visible-sm-inline-block js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                        <button type="button" onClick={() => goBack()} style={{color: theme.color.white}} className="sidedrawer-toggle mui--hidden-xs mui--hidden-sm js-hide-sidedrawer"><i className="fa fa-chevron-left"></i></button>
                    </>
                }
                title={<span className="mui--text-title" style={{color: theme.color.white}}>Email Verification</span>}
                bgColor={theme.color.info}
            />
            <div className="mui-container" style={{padding: '80px 15px 15px 15px'}}>
                <OTPVerifyForm 
                    via="email"
                    to={user?.information?.email}
                    onResend={() => resendOTP()}
                    onSubmit={(values) => onSubmit(values)}
                />
            </div>
        </div>
    )
   
}

export default VerifyEmail
