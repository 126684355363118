import React from 'react'
import { Link } from 'react-router-dom'
import Button from './../components/common/button'

function Welcome() {
    return (
        <div className="mui-container d-flex flex-column" style={{height: '100%'}}>
            <div className="mui--text-center flex1">
                <img src="/images/card_logo_new.png" className="logo" alt=""/>
            </div>
            <div className="mui-row flex0">
                <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                <div className="mui-col-lg-6 mui-col-md-8 mui-col-sm-10 mui-col-xs-12">
                    <Link id="btnCard" to="/auth/login" style={{display: 'none'}}></Link>
                    <Button bgColor="info" label="Just-iD Card" type="button" onClick={() => document.getElementById('btnCard').click()}/>
                </div>
            </div>
            <div className="mui-row flex0" style={{marginBottom: 100}}>
                <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                <div className="mui-col-lg-6 mui-col-md-8 mui-col-sm-10 mui-col-xs-12">
                    <Link id="btnTemp" to="/temporary/first" style={{display: 'none'}}></Link>
                    {/* <Button bgColor="white" label="Create Temporary QR" type="button" onClick={() => document.getElementById('btnTemp').click()}/> */}
                </div>
            </div>
        </div>
    )
}

export default Welcome