import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from '../redux/actions'
import { Formik } from 'formik'
import * as Yup from 'yup'

function Register({ actions, history, is_fail, message}) {

    const onSubmit = async (values) => {
        if(values.checked) {
            let res = await actions.AuthRegister(values)
            if(res.success) {
                if(res.accessToken) {
                    localStorage.setItem('card_token', res.accessToken)
                    await actions.GetProfile()
                    history.push({pathname: '/verify/email' })
                }
            }
        }
    }

    return (
        <Formik 
            initialValues={{}}
            enableReinitialize
            onSubmit={(values) => onSubmit(values)}
            validationSchema={
                Yup.object().shape({
                    email: Yup.string().email().required().label('Email'),
                    password: Yup.string().required().label('Password'),
                    password_confirmation: Yup.string().required().label('Password Confirmation'),
                    checked: Yup.mixed().required()
                })
            }
        >
            {
                ({ values, handleSubmit, handleChange, setFieldValue, errors, touched }) => {
                    return (
                        <form className="mui-form" onSubmit={handleSubmit}>
                            { is_fail && <div style={{ textAlign: 'center', color: 'red'}}>{message}</div> }
                            <div className="mui-textfield mui-textfield--float-label">
                                <input type="text" required name="email" value={values.email} onChange={handleChange} />
                                <label>Email</label>
                                {errors.email && touched.email && <div style={{ color: 'red', marginTop: 5, fontSize: 12 }}>{errors.email}</div>}
                            </div>

                            <div className="mui-textfield mui-textfield--float-label">
                                <input type="password" name="password" value={values.password} onChange={handleChange}/>
                                <label>Password</label>
                                {errors.password && touched.password && <div style={{ color: 'red', marginTop: 5, fontSize: 12 }}>{errors.password}</div>}
                            </div>

                            <div className="mui-textfield mui-textfield--float-label">
                                <input type="password" name="password_confirmation" value={values.password_confirmation} onChange={handleChange}/>
                                <label>Password Confirmation</label>
                                {errors.password_confirmation && touched.password_confirmation && <div style={{ color: 'red', marginTop: 5, fontSize: 12 }}>{errors.password_confirmation}</div>}
                            </div>

                            <div className="mui-row">
                                <div className="mui-col-md-12 d-flex">
                                    <div className="flex0" style={{whiteSpace: 'nowrap'}}>
                                        <div className="mui-checkbox">
                                            <label>
                                                <input type="checkbox" value="" checked={values.checked ? true : false} onClick={() => setFieldValue('checked', !values.checked)}/>
                                                I accepted the 
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex0" style={{paddingLeft: 3, marginTop: 10, whiteSpace: 'nowrap'}}>
                                        <a href="https://just-id.com/term-privacy.html" target="_blank" rel="noopener noreferrer"> <span style={{color: '#2196F3'}}> Term and Privacy Policy</span> </a>
                                    </div>
                                </div>
                            </div>
                            { errors.checked && touched.checked && <div style={{ color: 'red', marginTop: 5, fontSize: 12 }}>{errors.checked}</div>}

                            <div className="mui-row mt-3">
                                <div className="mui-col-md-12">
                                    <button className="mui-btn btn-full mui-btn--primary" type="submit" style={{borderRadius: 20}}>Submit</button>
                                </div>
                            </div>
                        </form>
                    )
                }
            }
        </Formik>
    )
}


const mapStateToProps = (state, ownProps) => {
	return {
        is_fail: state.authStore.is_fail,
        message: state.authStore.message,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
