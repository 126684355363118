import config from '../config'

export const FetchHistoryApi = () => {
    const auth_token = localStorage.getItem("card_token")
    let request = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'application/json'
        }
    }
    return fetch(config.BASE_API + '/cards/histories', request).then(res => res.json())
}

export const CreateHistoryApi = (params) => {
    const auth_token = localStorage.getItem("card_token")
    let request = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'application/json'
        }
    }
    return fetch(config.BASE_API + '/cards/histories', request).then(res => res.json())
}

export default { FetchHistoryApi, CreateHistoryApi }