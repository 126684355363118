import * as auth from './auth/authAction'
import * as setting from './setting/settingAction'
import * as profile from './profile/profileAction'
import * as qrdata from './qrdata/qrdataAction'
import * as temporary from './temporary/temporaryAction'
import * as history from './history/historyAction'

export default {
    ...auth,
    ...setting,
    ...profile,
    ...qrdata,
    ...temporary,
    ...history
}