import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import actions from './../redux/actions'
import config from './../config'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Button from './../components/common/button'
// Firebase.
import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDR2iEKMdXCpmXZwBKXJ9Wu0N4hNHzrLO8",
    authDomain: "just-id-cards.firebaseapp.com",
    databaseURL: "https://just-id-cards.firebaseio.com",
    projectId: "just-id-cards",
    storageBucket: "just-id-cards.appspot.com",
    messagingSenderId: "362326445414",
    appId: "1:362326445414:web:bcc1c21ef39cbaf2708ca7",
    measurementId: "G-M03LN1B5X0"
};
  
// Instantiate a Firebase app.
firebase.initializeApp(firebaseConfig);

function Login({ is_success, actions, history, is_fail, message }) {
    const [email, setEmail] = useState('')
    const [password, setPass] = useState('')

    useEffect(() => {
        if(is_success) {
            // console.log(is_success)
            // history.push('/login')
            // window.location = '/#/home'
            history.replace('/home')
        }
    }, [is_success, history])

    const login = (e) => {
        e.preventDefault()
        actions.AuthLogin({ email, password })
    }

    const responseFacebook = (user) => {
        if(user.id) {
            let names = user.name.split(' ')
            actions.AuthSocial({ email: user.email, provider_id: user.id, provider_name: 'facebook', name: names[0], surname: names[1] })
        }
    }

    const googleLogin = () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            let names = user.displayName.split(' ')
            actions.AuthSocial({ email: user.email, provider_id: user.uid, provider_name: 'google', name: names[0], surname: names[1] })
            // ...
          }).catch(function(error) {
            // Handle Errors here.
            console.log(error)
            // var errorCode = error.code;
            // var errorMessage = error.message;
            // The email of the user's account used.
            // var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            // ...
          });
    }

    

    return (
        <div className="mui-container">
            <div className="mui--text-center">
                <img src="/images/card_logo_new.png" className="logo" alt=""/>
            </div>
            <div className="mui-row">
                <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">
                    <form className="mui-form" onSubmit={login}>
                        {is_fail ? <div style={{ textAlign: 'center', color: 'red'}}>{message}</div> : null}
                        <div className="mui-textfield mui-textfield--float-label">
                            <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>
                            <label>Email</label>
                        </div>
                        <div className="mui-textfield mui-textfield--float-label">
                            <input type="password" value={password} onChange={e => setPass(e.target.value)}/>
                            <label>Password</label>
                        </div>
                        
                        <div className="mui-row">
                            <div className="mui-col-md-12">
                                <Button bgColor="info" type="submit" label="SIGN IN"/>
                            </div>
                        </div>
                        <div className="mui--text-right" style={{marginBottom: 15}}>
                            <Link to="/forgotpassword" className="mui-btn mui-btn--flat" style={{paddingRight: 5}}>Forgot your password?</Link>
                        </div>
                        <div className="mui-row" style={{marginBottom: 15}}>
                            <div className="mui-col-xs-1"></div>
                            <div className="mui-col-xs-10 d-flex justify-center">
                                <div className="sigin-divider"></div>
                                <div className="text-divider"> OR SIGN IN WITH </div>
                                <div className="sigin-divider"></div>
                            </div>
                            <div className="mui-col-xs-1"></div>
                        </div>
                        <div className="mui--text-center" style={{marginBottom: 15}}>
                            <FacebookLogin
                                appId="324952838491106"
                                fields="name,email"
                                callback={responseFacebook}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} type="button" className="mui-btn mui-btn--primary btn-social bg-facebook mui-btn--raised"><i className="fa fa-facebook"></i></button>
                                )}
                            />
                            <button onClick={googleLogin} type="button" className="mui-btn mui-btn--primary btn-social mui-btn--raised" style={{ backgroundColor: '#fff', padding: '3px 10px'}}><img src={'/images/google-icon.svg'} style={{ width: 16}} alt=""/></button>
                            
                            <a href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${config.LINE_CLIENT_ID}&redirect_uri=${config.LINE_REDIRECT_URI}&state=login&scope=profile%20openid%20email`} className="mui-btn mui-btn--primary btn-social bg-line mui-btn--raised"><img src="/images/line-logo.png" style={{height: 35}} alt=""/></a>
                        </div>
                        <div className="mui--text-center">
                            <Link to="/auth/register" className="mui-btn mui-btn--flat">
                                Don't have an account? <span style={{color: '#2196F3'}}>Sign up</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
	return {
        is_success: state.authStore.is_success,
        is_fail: state.authStore.is_fail,
        message: state.authStore.message,
	}
}
  
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
