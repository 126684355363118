import React from 'react'
import PropTypes from 'prop-types'

const InputText = ({type, label, name, value, onChange, noFloat, required}) => {
    return (
        <div className={`mui-textfield ${noFloat ? '' : 'mui-textfield--float-label'}`}>
            <input type={type ? type : 'text'} name={name} className={value ? 'mui--is-touched' : ''} onChange={onChange} value={value || ''} required={required}/>
            <label>{label}</label>
        </div>
    )
}

InputText.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    noFloat: PropTypes.bool,
    required: PropTypes.bool
};

InputText.defaultTypes = {
    required: false
}

export default InputText