import React from 'react'
// import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import QRCode from 'qrcode-react'
import styled from 'styled-components'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import StopIcon from '@material-ui/icons/Stop'

const TextTitle = styled.span`
    font-size: 24px;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
`;

const QRBody = styled.div`
    background-color: #2f4fd1;
    background-image: url("/images/card-body-bg.jpg");
    background-repeat: repeat-y;
    color: #fff;
    padding: 10px;
`;

const QRBodyText = styled.div`
    color: #fff;
    display: block;
    ${props => props.bold ? 'font-weight: bold;' : null}
`;

const QRFooter = styled.div`
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #2a2d7e;
    background-image: url("/images/card-footer-bg.jpg");
    background-repeat: repeat-y;
    color: #fff;
    padding: 10px;
`;

const MyCard = ({ qrdata }) => {
    let { data } = qrdata
    const types = { level0: 'Zero', level1: 'Standard' }
    return (
        <div className="mui-row">
                <div className="mui-col-sm-1 mui-col-md-2 mui-col-lg-3"></div>
                <div className="mui-col-xs-12 mui-col-sm-10 mui-col-md-8 mui-col-lg-6">
                    <div className="mui-panel mt-5" style={{paddingBottom: 0, background: 'white url("/images/card-bg.jpg") repeat-y'}}>
                        <div className="mui--text-center">
                            <img src="/images/card_logo_new.png" style={{width: 200}} alt=""/>
                        </div>
                        <div className="mui-row mt-4">
                            <div className="mui-col-xs-5 mui--text-right">
                                { data && data.attachment && data.attachment.avatar ? <img src={data && data.attachment && data.attachment.avatar ? data.attachment.avatar : null} style={{borderRadius: 5, height: 120, objectFit: 'cover', width: 100, border: '2px solid rgb(36, 78, 216)'}} alt=""/> : null }
                            </div>
                            <div className="mui-col-xs-7">
                                <TextTitle>{data ? data.name : ''}</TextTitle>
                                <TextTitle>{data ? data.surname : ''}</TextTitle>
                                {qrdata.user && qrdata.user.jcid && <p className="mt-1" style={{ marginBottom: 5 }}>{qrdata.user.jcid}</p> }
                                <p className="mt-1" style={{ marginBottom: 5 }}>KYC level: <strong>{data && data.kyc_level ? types['level'+data.kyc_level]: ''}</strong></p>
                                <p className="mt-1" style={{ marginBottom: 5 }}>Start: {qrdata.started ? dayjs(qrdata.started).format("DD-MMM-YYYY HH:mm") : ''}</p>
                                <p className="mt-1" style={{ marginBottom: 5 }}>Expire: {qrdata.expires ? dayjs(qrdata.expires).format("DD-MMM-YYYY HH:mm") : ''}</p>
                                
                            </div>
                        </div>
                        <QRBody className="mui-row mt-3">
                            <div className="mui-col-xs-12">

                                <div className="mui-row mt-2">
                                    <div className="mui-col-xs-4 mui-col-sm-4 mui-col-md-6 mui-col-lg-6 d-flex">
                                        <div className="flex-0" style={{paddingTop: 2}}>
                                            {/* <div style={{ width: 6, height: 36, display: 'inline-block', backgroundColor: '#fff'}}></div> */}
                                            <StopIcon sx={{ color: '#FF8800' }} />
                                        </div>
                                        <div className="flex-1" style={{paddingLeft: 10}}>
                                            <QRBodyText bold>Phone {data && data.phone_verify ? <VerifiedUserIcon fontSize="small"/> : null}</QRBodyText>
                                            <QRBodyText>{data && data.phone ? data.phone : ''}</QRBodyText>
                                        </div>
                                    </div>
                                    <div className="mui-col-xs-8 mui-col-sm-8 mui-col-md-6 mui-col-lg-6 d-flex">
                                        <div className="flex-0" style={{paddingTop: 2}}>
                                            {/* <div style={{ width: 6, height: 36, display: 'inline-block', backgroundColor: '#fff'}}></div> */}
                                            <StopIcon sx={{ color: '#FF8800' }} />
                                        </div>
                                        <div className="flex-1" style={{paddingLeft: 10}}>
                                            <QRBodyText bold>Email {data && data.email_verify ? <VerifiedUserIcon fontSize="small"/> : null}</QRBodyText>
                                            <QRBodyText>{data && data.email ? data.email : ''}</QRBodyText>
                                        </div>
                                    </div>
                                </div>
                                <div className="mui-row mt-4">
                                    <div className="mui-col-xs-12 mui--text-center">
                                        <div id="QRWrapper" style={{ display: 'inline-block', height: 155, width: 160, padding: '15px 10px 10px 10px', backgroundColor: '#fff' }}>
                                            <QRCode 
                                                value={qrdata.qrid || ''} 
                                                bgColor={'transparent'} 
                                                level="H" 
                                                size={150} 
                                                renderAs={'canvas'}
                                                logo="/images/card_icon.jpg"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mui-row mt-3">
                                    <div className="mui-col-xs-12 mui--text-center">
                                        
                                    </div>
                                </div>
                            </div>
                        </QRBody>

                        <QRFooter className="mui-row">
                            <div className="mui-col-xs-12 mui--text-center">
                                CARD.JUST-ID.COM
                            </div>
                        </QRFooter>
                    </div>
                </div>
            </div>
    )
}

// MyCard.propTypes = {
//     qrdata: PropTypes.exact({
//         visitor_entrance_id: PropTypes.any,
//         user: PropTypes.object,
//         data: PropTypes.exact({
//             attachment: PropTypes.object,
//             name: PropTypes.string
//         })
//     })
// }

export default MyCard