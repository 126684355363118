import initialState from './../initialState'
import types from './../actionTypes'

export default (state = initialState.temporaryStore, action = {}) => {

    switch(action.type) {

		case types.SET_AVATAR: {
			return {
				...state,
				avatar: action.payload,
			}
        }
        
        case types.SET_INFORMATION: {
			return {
				...state,
				information: action.payload,
			}
        }
        
        case types.SET_ATTACHMENT: {
            let { idcard, passport, driving_license } = action.payload
			return {
				...state,
                idcard,
                passport,
                driving_license
			}
		}

		case types.SET_INFORMATION_ATTACHMENT: {
			let { information, idcard, passport, driving_license } = action.payload
			return {
				...state,
				information,
                idcard,
                passport,
                driving_license
			}
		}

        default: 
            return state
    }
}