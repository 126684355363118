import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const color = {
    info: '#00B6F0',
    white: '#fff',
    purple: '#D7E0F9',
    grey: 'silver'
}

const fontColor = {
    info: '#fff',
    white: 'rgba(0,0,0,.87)',
    purple: '#2633C5',
    grey: '#fff'
}

const Btn = styled.button`
    color: ${props => props.color};
    ${props => props.outlined && `
        background-color: transparent;
        border: 1px solid #00B6F0;
        color: #00B6F0;
    `}
`;

const Button = ({ type, onClick, label, bgColor, className, full, disabled, outlined }) => {
    const handleClick = () => {
        if(onClick) {
            onClick()
        }
    }

    const styles = {
        // color: fontColor[bgColor],
        backgroundColor: color[bgColor],
        borderRadius: 20,
        height: 40
    }

    return (
        <Btn outlined={outlined} color={fontColor[bgColor]} className={`mui-btn ${full ? 'btn-full' : ''} mui-btn--primary ${className ? '' : 'mui-btn--raised'}`} type={type ? type : 'button'} onClick={handleClick} style={styles} disabled={disabled}>{label}</Btn>
    )
}

Button.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    full: PropTypes.bool,
    disabled: PropTypes.bool,
    outlined: PropTypes.bool,
    bgColor: PropTypes.oneOf(['info', 'white', 'purple', 'grey']),
    onClick: PropTypes.func
}

Button.defaultProps = {
    full: true,
    disabled: false
}

export default Button