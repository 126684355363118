import {combineReducers} from 'redux';
import auth from './auth/authReducer'
import setting from './setting/settingReducer'
import profile from './profile/profileReducer'
import qrdata from './qrdata/qrdataReducer'
import temporary from './temporary/temporaryReducer'
import history from './history/historyReducer'

const rootReducer = combineReducers({
    // short hand property names
    authStore: auth,
    settingStore: setting,
    profileStore: profile,
    qrdataStore: qrdata,
    temporaryStore: temporary,
    historyStore: history
})
  
export default rootReducer;
